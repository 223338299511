import {FetchError} from 'types/errors'

const request = async (method: string, path: string, data: object | undefined, options: RequestInit = {}): Promise<any> => {
  options.method = method
  options.headers = new Headers(options.headers)

  if (data) {
    options.headers.set('Content-Type', 'application/json')
    options.body = JSON.stringify(data)
  }

  let res = await fetch(`${process.env.REACT_APP_SERVER_URL || ''}${path}`, options)
  if (!res.ok) {
    let error: FetchError
    const contentType = res.headers.get('Content-Type')
    if (contentType?.includes('application/json')) {
      const data: {message: string, type?: string} = await res.json()
      error = new Error(data.message)
      error.type = data.type
    } else {
      error = new Error(await res.text())
    }
    throw error
  }

  const contentType = res.headers.get('Content-Type')
  if (contentType?.includes('application/json'))
    res = await res.json()

  return res
}

type requestFn = (path: string, data?: object | undefined, options?: RequestInit) => any

export const get = (path: string, options?: RequestInit) =>
  request('GET', path, undefined, options)

export const post: requestFn = (path, data, options) =>
  request('POST', path, data, options)

export const patch: requestFn = (path, data, options) =>
  request('PATCH', path, data, options)

export const put: requestFn = (path, data, options) =>
  request('PUT', path, data, options)

export const del: requestFn = (path, data, options) =>
  request('DELETE', path, data, options)
