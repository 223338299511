import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'competences',
      title: 'Markér hvad du er kvalificeret til lige nu',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Autoriseret revisor',
          value: 'authorized_accountant',
        }, {
          text: 'Udarbejdelse og indberetning af årsregnskaber for selskaber der ikke har revisionspligt',
          value: 'accounting',
        }, {
          text: 'Udvidet selvangivelse for virksomheder på virksomhedsskatteordningen',
          value: 'vso',
        }, {
          text: 'Bogføring og indberetning af udvidet selvangivelse',
          value: 'bookkeeping',
        }, {
          text: 'Bogføring og forskellige afstemninger',
          value: 'reconciliation',
        }, {
          text: 'Ingen af ovenstående, men jeg kan noget andet som i måske har brug for',
          value: 'other',
        },
      ]
    }, {
      if: (values) => (values.competences && (values.competences.indexOf('other') >= 0)),
      type: 'textarea',
      name: 'other_qualifications',
      title: 'Skriv hvilke andre kompetancer du har',
      required: false,
      placeholder: '',
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: true,
      placeholder: 'email@email.com',
    }, {
      type: 'input',
      name: 'hours',
      title: 'Hvor mange timer ønsker du at arbejde per uge i vores højsæson der går fra 1. januar til 1. september',
      required: false,
      placeholder: '40',
      mask: '999',
    },{
      type: 'checkboxes',
      name: 'salary_or_freelancer',
      title: 'Er du kun interesseret i at arbejde som lønmodtager, eller også som freelancer hvor du fakturerer os for de timer du arbejder. Markér det du er interesseret i',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Jeg er interesseret i at arbejde som freelancer hvor jeg fakturerer per time, i hvert fald i starten',
          value: 'freelancer',
        }, {
          text: 'Jeg er interesseret i at arbejde som lønmodtager',
          value: 'salary',
        },
      ]
    }, {
      if: (values) => (values.salary_or_freelancer && (values.salary_or_freelancer.indexOf('freelancer') >= 0)),
      type: 'input',
      name: 'hourly',
      title: 'Hvis du arbejdede som freelancer, hvor mange kroner tager du i såfald per time (eks. evt. moms)?',
      required: false,
      placeholder: '100',
      mask: '999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    },{
      if: (values) => (values.salary_or_freelancer && (values.salary_or_freelancer.indexOf('salary') >= 0)),
      type: 'input',
      name: 'monthly',
      title: 'Hvis du arbejdede som lønmodtager på fuldtid, hvor meget ville du ønske at få i bruttoløn per måned?',
      required: false,
      placeholder: '20000',
      mask: '99999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    }, {
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
