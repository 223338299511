export enum ElementType {
  input = 'input',
  textarea = 'textarea',
  checkboxes = 'checkboxes',
  rate = 'rate',
  custom = 'custom',
  bankLogin = 'bankLogin',
  companySearch = 'companySearch',
  payment = 'payment'
}

export enum ButtonAction {
  next = 'next',
  submit = 'submit'
}

export type Values = {
  [key: string]: any
  cvr_search: CompanyInfo
}


export type Condition = ((values: Values) => boolean) | {
  name: string,
  op?: string,
  value: any,
}

export type CheckoutItem = { description: string, expression: ((values: Values, subtotal: number) => number), _value?: number}

export interface Item {
  name: string
  dependencies?: string[]
  if?: Condition
}

export interface Question extends Item {
  title: string
  dynamicTitle?: (values: Values) => string
  type: keyof typeof ElementType
  name: string,
  default?: any
  required?: boolean
  text?: string
  validate?: (values: Values) => string
}

export interface InputData extends Question {
  placeholder?: string
  inputType?: string
  mask? : string //9=0-9  a=a-z,A-Z  *=0-9, a-z, A-Z
}

export interface PaymentData extends Question {
  currency: string
  _amount?: number
  description: string
  checkoutItems : Array<CheckoutItem>
}

export interface TextAreaData extends Question {
  placeholder?: string
}

export interface Option {
  if?: Condition
  text: string
  link?: string
  value?: string
}

export interface CheckboxesData extends Question {
  multiple?: boolean
  options: Option[]
}

export interface RateData extends Question {
  rateMin: number
  rateMax: number
  rateStep?: number
}

export interface CustomData extends Item {
  type: 'custom'
  html?: string
  buttonsAlign?: string
  buttons?: (keyof typeof ButtonAction)[]
}

export interface BankLoginData extends Item {
  type: 'bankLogin'
  title: string
  name: string
  required: boolean
  loginRequiredText?: string
  loginExistsText?: string
}

export interface CompanySearchData extends Question {
  placeholder: string
  noCompanyText: string
}

export type Questions =
  CompanySearchData |
  InputData |
  TextAreaData |
  CheckboxesData |
  RateData

export type AnyElement =
  Questions |
  PaymentData |
  BankLoginData |
  CustomData

export interface SurveyJSON {
  success: string
  errors: {
    required: string
  }
  locales: {
    [key: string]: {
      [key: string]: string
    }
  },
  defaultLocale: string,
  allowNewCustomer?: boolean,
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  items: AnyElement[]
}

export interface CompanyInfo {
  address: string
  cvr: number
  første_regnskabsperiode_slut: string
  første_regnskabsperiode_start: string
  livsforloeb: string
  name: string
  nyesteAarsbeskaeftigelse: any
  nyesteBeliggenhedsadresse: {
    conavn: any
    husnummerFra: number
    postdistrikt: string
    postnummer: number
    vejnavn: string
  }
  nyesteHovedbranche: {
    Kode: string
    Tekst: string
  }
  nyesteBibranche1: any
  nyesteBibranche2: any
  nyesteBibranche3: any
  nyesteErstMaanedsbeskaeftigelse: any
  nyesteKvartalsbeskaeftigelse: any
  nyesteMaanedsbeskaeftigelse: any
  nyesteNavn: string
  nyesteVirksomhedsformKortBeskrivelse: string
  postalCode: number
  revision_fravalgt: string
  sammensatStatus: string
  status: string
  stiftelsesDato: string
  type: string
  virksomhedsstatus: string
}
