export const routes = {
  websocket: (sessionId: number, id: string, form: string) => `/?session_id=${sessionId}&customer_id=${id}&form=${form}`,
  api: {
    response: {
      update: (id: string, form: string) => `/api/v1/responses/${id}/${form}`,
      submit: (id: string, form: string) => `/api/v1/responses/${id}/${form}/submit`,
      create: () => `/api/v1/responses`,
    },
    stripeCheckout: {
      createCheckoutSession: () => `/api/v1/stripeCheckout/create-checkout-session`
    },
    customer: {
      createCustomer: () => `/api/v1/customer/create-customer`,
      getViiaLoginLink: () => `/api/v1/customer/get-viia-login-link`,
      checkViiaAccess: () => `/api/v1/customer/check-viia-access`,
    }
  }
}