import React from 'react'
import Survey from './Model'

type Props = {
  model: Survey
  children: React.ReactNode
}

export default function HistoryListenerComponent ({model, children}: Props)  {
  React.useEffect(() => {
    const listener = () => {
      const match = window.location.search.match(/\bstep=([^&]+)/)
      if (match)
        model.setStep(match[1])
    }
    window.onpopstate = listener
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}
