import React from "react"
import {search} from "Survey/Elements/CompanySearch/actions"
import State from 'Survey/Elements/CompanySearch/state'
import {observer} from "mobx-react"
import inputStyle from "Survey/Elements/Input/style.module.scss"

export default observer(function SearchInputComponent ({state, placeholder}: {state: State, placeholder: string})  {
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    (inputRef.current as any as HTMLInputElement).onkeydown = (e) =>
      e.stopPropagation()
  }, [])

  return <input
    ref={inputRef}
    value={state.search}
    onChange={({target: {value}}) => search(state, value)}
    className={inputStyle.input}
    placeholder={placeholder}
  />
})
