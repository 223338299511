import { SurveyJSON } from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'sent_receipts',
      title: 'Har du indsendt alle bilag for regnskabsperioden?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    }, {
      type: 'checkboxes',
      name: 'sent_csv',
      title: 'Har du indsendt csv fil(er) med dine bank transaktioner?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    }, {
      type: 'custom',
      name: 'header',
      html: '<h3>Tryk indsend for at sende dine svar</h3>' +
        '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['submit']
    },
  ]
}

export default json
