import React from 'react'
import Survey from './Model'
import {nextQuestion, prevQuestion} from "./actions"

type Props = {
  model: Survey
  children: React.ReactNode
}

export default function KeyboardEventComponent ({model, children}: Props)  {
  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.shiftKey)
        return

      const {key} = e
      if (key === 'Enter' /*|| key === ' '*/ || key === 'ArrowDown')
        nextQuestion(model)
      else if (key === 'ArrowUp')
        prevQuestion(model)
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}
