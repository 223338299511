import { SurveyJSON } from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  allowNewCustomer: true,
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ?</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ?</b> + <b>Enter ?</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'custom',
      name: 'text',
      html: '<blockquote>Revisortest - Årsafslutning<br><p>Prøv at besvare nedenstående spørgsmål. Hvis du sidder fast i et spørgsmål, så gå videre til det næste.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next'],
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Skriv din e-mail /navn*',
      required: true,
      placeholder: 'eksempel@eksempel.com',
    },{
      type: 'input',
      name: 'Udbytte1',
      title: 'Udbytte (spørgsmål 1)',
      text: 'Din kunde har i årets løb tjent godt i virksomheden og vil derfor gerne tage udbytte i løbet af selskabets første regnskabsår. Hvornår på året kan kunden udbetale udbyttet? Hvor meget kan kunden udbetale? Er der krav der skal opfyldes inden udbetaling f.eks. mellembalance?',
      required: true,
      placeholder: 'Skriv svaret her...',
    },{
      type: 'input',
      name: 'Udbytte2',
      title: 'Udbytte (spørgsmål 2)',
      text: 'Din kunde har ved denne årsafslutning ønske om udbytte. Selskabet har en egenkapital på kr. 145.000 bestående af selskabskapital på kr. 50.000 og overført resultat på kr. 95.000. Kunden ønsker at tage udbytte op til progressionsgrænsen for ægtepar (kr. 113.000 i 2021 / kr. 114.400 i 2022). Hvad svarer du kunden?',
      required: true,
      placeholder: 'Skriv svaret her...',
    },{
      type: 'input',
      name: 'Udbytte3',
      title: 'Udbytte (spørgsmål 3)',
      text: 'Din kunde har besluttet at tage kr. 300.000 i udbytte fra sit selskab - og er klar over at skatten vil ende på mere end de 27% og vil derfor gerne indeholde den fulde skat ved indberetning og udbetaling af restudbyttet. <br/> 1) Kan man indberette og indbetale den ekstra skat (ud over de lovpligtige 27%) allerede ved indberetningen af udbyttet?  <br/> 2) Hvor stor bliver skatten?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Udbytte4',
      title: 'Udbytte (spørgsmål 4)',
      text: 'Du laver et regnskabet for et holdingselskab. Datterselskabet har i årsregnskab for samme år, vedtaget at udlodde kr. 300.000 til moderselskabet (datterselskabet udloddede sidste år kr. 200.000 til moderselskabet). Skal denne information medtages på moderselskabets selvangivelse?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Udbytte5',
      title: 'Udbytte (spørgsmål 5)',
      text: 'Du laver regnskab for et selskab, der udlodder kr. 100.000 i udbytte til moderselskabet (100% ejet og sambeskattet). Hvor stort et beløb skal der betales i udbytteskat? Er det datterselskabet eller moderselskabet der skal foretage indbetalingen til Skat?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Afskrivninger1',
      title: 'Afskrivninger (spørgsmål 1)',
      text: 'Din kunde har haft et svært år og vil gerne præsentere et "pænt" regnskab for selskabet bl.a. overfor banken. Kunden vil derfor gerne have, at du ikke afskriver på den maskine, der er købt for kr. 200.000 i starten af regnskabsåret. Hvad svarer du kunden? Kan kunden "spare" de kr. 50.000 og forbedre selskabets regnskabsmæssige egenkapital?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Afskrivninger2',
      title: 'Afskrivninger (spørgsmål 2)',
      text: 'Din kundes enkeltmandsvirksomhed har haft underskud i regnskabsåret og vil gerne have at der ikke afskrives på de maskiner der er anskaffet i løbet af året. Hvad svarer du kunden? Kan kunden undlade at afskrive i skatteregnskabet? Hvis ja, hvorfor? Hvis nej, hvorfor?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Udskudt_skat1',
      title: 'Udskudt skat (spørgsmål 1)',
      text: 'Selskabet aktivmasse har en regnskabsmæssig værdi på kr. 100.000 og en skattemæssig værdi på kr. 80.000,-. Hvis nogen, hvor meget vil den udskudte skat blive? Og hvordan skal den udskudte skat konteres? Er det et aktiv eller et passiv?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Udskudt_skat2',
      title: 'Udskudt skat (spørgsmål 2)',
      text: 'Ændrer det noget på ovenstående spørgsmål, at selskabet har et fremførbart underskud på kr. 100.000 (der på grund af aktivets stilling ikke er medregnet som et skatteaktiv i selskabets regnskab)? Begrund dit svar!',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Sambeskatning1',
      title: 'Sambeskatning (spørgsmål 1)',
      text: 'Din kundes selskab har købt en ejerandel på 50% i et andet selskab. Skal dit kundens selskab og det anskaffede selskab sambeskattes?',
      required: true,
      placeholder: '',
    },{
      type: 'input',
      name: 'Sambeskatning2',
      title: 'Sambeskatning (spørgsmål 2)',
      text: 'Ændrer det noget på svaret på ovenstående spørgsmål, at din kunde ved anskaffelse af selskabet, indgår en aftale med den øvrige ejere (af det selskab der købes), at det er hans stemme, der er afgørende ved stemmelighed for beslutninger omkring driften af det selskab der købes?',
      required: true,
      placeholder: '',
    }, {
      type: 'custom',
      name: 'header',
      html: '<h3>Er du klar til at indsende?</h3>' +
          '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['submit']
    },
  ]
}

export default json
