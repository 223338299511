import React from 'react'
import {routes} from 'config/routes'
import Survey from './Model'
import {setResponseReady} from './actions'

const wsUrl = (process.env.REACT_APP_SERVER_URL || window.location.origin)
  .replace(`http://`, 'ws://')
  .replace(`https://`, 'wss://')

const update = (model: Survey, {data, step}: {data?: object, step?: string}) => {
  const loading = model.state === 'loading'

  if (data)
    model.setValues(data)
  if (step)
    model.setStep(step, loading)

  if (loading)
    setResponseReady(model)
}

const submit = (model: Survey) => {
  model.state = 'submitted'
}

let connectionTimeout: any = undefined

const initWebsocket = (model: Survey) => {
  const ws = new WebSocket(wsUrl + routes.websocket(model.sessionId, model.customerId, model.formName))
  ws.onopen = () => {
    model.isConnectionOk = true
    clearTimeout(connectionTimeout)
  }
  ws.onerror = (e: Event) => {
    console.error(e)
  }
  ws.onmessage = (e) => {
    const {type, payload} = JSON.parse(e.data)
    if (type === 'update')
      update(model, payload)
    else if (type === 'submit')
      submit(model)
  }
  ws.onclose = () => {
    initWebsocket(model)
    connectionTimeout = setTimeout(() => model.isConnectionOk = false, 500)
  }
}

type Props = {
  model: Survey
  children: React.ReactNode
}

export default function ConnectWebsocketComponent ({model, children}: Props) {
  React.useEffect(() => {
    initWebsocket(model)
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}
