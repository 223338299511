import { SurveyJSON } from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'custom',
      name: 'header',
      html: '<h3>Årsafslutningsskema - Personlig virksomhed</h3>' +
        '<blockquote>For at vi kan færdiggøre din selvangivelse har vi ' +
        'behov for at du udfylder følgende skema med eventuelle fradrag ' +
        'du måtte have, som ikke automatisk overføres fra SKAT. ' +
        'I nedenstående skema kan du se en oversigt over disse ' +
        'typer fradrag. Udfyld venligst skemaet, hvis du har nogle ' +
        'af disse fradrag, og skriv ”Nej” i feltet hvis du ikke har ' +
        'fradrag. <br/>' +
        'Den eneste måde vi kan sikre at du får alle dine fradrag ' +
        'er gennem din information. Så husk at sørge for at du har ' +
        'indberettet alle eventuelle fradrag til os. ' +
        'Er du i tvivl om noget, så skriv til ' +
        '<a href="mailto:kundeservice@revisor1.dk">kundeservice@revisor1.dk</a>' +
        '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },
    /*{
      type: 'checkboxes',
      name: 'intet_at_angive',
      title: 'Intet at angive?',
      required: false,
      multiple: false,
      text: 'Hvis du intet har at angive, så marker feltet nedenfor. ' +
        'Klik efterfølgende på knappen "send" nederst på siden.',
      options: [
        {
          text: 'Intet at angive',
          value: 'intet_at_angive',
        }
      ]
    },*/
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'private_loans',
      title: 'Har du fradrag for: Private lån',
      text: 'Beskrivelse/eksempel: "Har du lån uden for banker eller ' +
        'pengeinstitutter som du betaler renter på: Angiv årlig rente og ' +
        'CPR nummer eller CVR nummer på långiver"',
      required: false,

    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'befordringsfradrag',
      title: 'Har du fradrag for: Befordringsfradrag',
      text: 'Beskrivelse/eksempel: "Transport mellem hjem og arbejde der ' +
        'udover 24 km. Der skal angives antal km og antal arbejdsdage."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'servicefradrag',
      title: 'Har du fradrag for: Servicefradrag',
      text: 'Beskrivelse/eksempel: "Eksempelvis fradrag for havearbejde". ' +
        '(Dette fradrag skal du selv indberette via MitID på Tast Selv.)',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'hejsegodtgørelse',
      title: 'Har du fradrag for: Rejsegodtgørelse / diæter',
      text: 'Beskrivelse/eksempel: "Rejsegodtgørelse for rejser i firmaøjemed, som ikke dækkes af firmaet."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'børnebidrag',
      title: 'Har du fradrag for: Børnebidrag',
      text: 'Beskrivelse/eksempel: "Som betaler skal du angive betalt beløb og CPR nummer på barnet."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'Ægtefællebidrag',
      title: 'Har du fradrag for: Ægtefællebidrag',
      text: 'Beskrivelse/eksempel: "Som betaler skal angive betalt beløb og CPR nummer på ægtefælle."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'garanti',
      title: 'Har du fradrag for: Garanti- og stiftelsesprovision',
      text: 'Beskrivelse/eksempel: "På lån med en løbetid på under 2 år, har man fradrag for Garanti- og stiftelsesomkostninger."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'etableringskonto',
      title: 'Har du fradrag for: Etableringskonto og Iværksætterkonto',
      text: 'Beskrivelse/eksempel: "Oplys om du indbetaler til en af disse konti."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'fradrag_over_2000_kr',
      title: 'Har du fradrag for: Fradrag for tab på fordringer over 2000 kr',
      text: 'Beskrivelse/eksempel: "Eksempelvis tilgodehavende hos konkursbo, der ikke dækkes af andre ordninger."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'textarea',
      name: 'eventuelle_andre_fradrag',
      title: 'Har du fradrag for: Eventuelle andre fradrag',
      text: 'Beskrivelse/eksempel: "Husk at oplyse hvis du har øvrige ' +
      'fradrag som ligger uden for de ovenfor nævnte punkter. '+
      'Eksempelvis bankkonti/indtægter/pensioner i udlandet der ikke automatisk indberettes"',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'kørsel_for_virksomhed_km',
      title: 'Øvrige oplysninger: Kørsel for virksomhed i egen bil (KM-penge)',
      text: 'Beskrivelse/eksempel: "Angiv venligst km-tal for året. Medtag ikke kilometer som du allerede har indsendt bilag for."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'kørsel_for_virksomhed_percentage',
      title: 'Øvrige oplysninger: Kørsel for virksomhed i egen bil (Procentvis fradrag for erhvervsmæssig kørsel i egen bil.)',
      text: 'Beskrivelse/eksempel: "Denne mulighed kan KUN anvendes såfremt du ikke modtager ' +
      'KM-penge. Angiv venligst den procentvise andel af din kørsel som kan tilskrives ' + 
      'erhvervsmæssig brug" (Skal kunne dokumenteres i form af kørebog, hvis SKAT ønsker at se dokumentation).',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'værdi_af_Færdigvare_lager',
      title: 'Øvrige oplysninger: Værdi af Færdigvare-lager ved regnskabsårets afslutning (Dkk)',
      text: 'Beskrivelse/eksempel: "Angiv venligst værdien af dit færdigvarelager i kroner."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'svind_af_lagervarer',
      title: 'Øvrige oplysninger: Svind af lagervarer i løbet af regnskabsåret (Dkk)',
      text: 'Beskrivelse/eksempel: "Angiv eventuelt svind af lagervarer i løbet af året."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'procentvis_fradrag',
      title: 'Øvrige oplysninger: Procentvis fradrag for erhverv i egen bolig',
      text: 'Beskrivelse/eksempel: "Hvis egen bolig bliver brugt til erhverv, ' +
      'og du opfylder kravene til erhvervsbrug, kan du få fradrag for den ' + 
      'procentdel af bolig der anvendes til erhverv. Angiv den samlede årlige ' +
      'omkostning der kan tilskrives erhvervsmæssig brug af bolig:" ' +
      '(Det er vigtig at sikre sig at alle krav er opfyldt, da SKAT ' +
      'traditionelt er meget opmærksomme på denne type fradrag).' + 
      'Du kan se SKAT\'s krav her: https://skat.dk/erhverv/egen-virksomhed/fradrag-for-virksomhedens-udgifter',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'checkboxes',
      name: 'solgt_ejendom',
      title: 'Har du solgt ejendom i løbet af regnskabsåret?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'checkboxes',
      name: 'solgt_værdipapirer',
      title: 'Har du solgt værdipapirer i løbet af regnskabsåret?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'input',
      name: 'medhjælpende_ægtefælle',
      title: 'Medhjælpende ægtefælle?',
      text: 'Beskrivelse/eksempel: "Hvis du har en medhjælpende ægtefælle så angiv hvor stort et beløb din ægtefælle modtager."',
      required: false,
    },
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'checkboxes',
      name: 'afskrivninger',
      title: 'Afskrivninger - Har du solgt aktiver?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    },
    {
      type: 'checkboxes',
      name: 'telefon_internet',
      title: 'Vælg venligst nedenfor om du skal beskattes af fri telefon/internet eller ej',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Beskattet af fri telefon/internet (3100 kr om året for 2023) - typisk hvis man ikke har privat telefon, og har råderet over en erhversmæssig telefon.',
          value: 'beskattet af fri telefon/internet ',
        },
        {
          text: 'Ren erhversmæssig telefon/internet (ingen privat beskatning) - SKAT vil sandsynligvis kræve at man kan dokumentere en privat telefon ved siden af, som anvendes til dine private opkald.',
          value: 'ren erhversmæssig telefon/internet',
        },
        {
          text: 'Jeg har ikke fri telefon/internet',
          value: 'jeg har ikke fri telefon/internet',
        }
      ]
    },
    {
      type: 'custom',
      name: 'header',
      html: '<h3>Er du klar til at indsende?</h3>' +
        '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['submit']
    },
  ]
}

export default json
