import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.monthly && !isNaN(values.monthly) && values.monthly < 50000 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 400;
    } else if (values.monthly && !isNaN(values.monthly) && values.monthly < 60000 && (values.competences && (values.competences.indexOf('controller') >= 0))) {
      return 200;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'competences',
      title: 'Markér de opgaver du kunne ønske at arbejde med',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Arbejde med bogholderi',
          value: 'bookkeeping',
        }, {
          text: 'Lave revisor arbejde',
          value: 'accounting',
        }, {
          text: 'Financial controller arbejde for kunder',
          value: 'controller',
        }, {
          text: 'Sælge vores ydelser til potentielle kunder, efter at de har bedt os om at kontakte dem',
          value: 'onboarding',
        }, {
          text: 'Kundeservice, dvs. ringe til kunder der har brug for hjælp eller potentielt er sure over noget',
          value: 'customer_service',
        },
      ]
    }, {
//      if: (values) => (values.competences === 'bookkeeping' || values.competences === 'accounting'),
      //if: (values) => (values.competences === 'accounting'),
      if: (values) => (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0)),
      type: 'textarea',
      name: 'test_question',
      title: 'På hvilke måder kan man kontere et besøg i en bar?',
      required: false,
      placeholder: 'Skriv dit svar her',
    },{
      type: 'input',
      name: 'monthly',
      title: 'Hvor meget forventer du at tjene per måned?',
      required: true,
      placeholder: '40000',
      mask: '99999',
      validate: (values) => { return (isNaN(values.monthly) || !values.monthly ? "A number is required" : "")}
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: false,
      placeholder: 'email@email.com',
    }, {
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
