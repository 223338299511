import {action, observable, computed, toJS} from 'mobx'
import {Question, AnyElement, InputData, Condition, CheckboxesData, Option, PaymentData, CheckoutItem} from 'Survey/types'
import {SurveyJSON} from "Survey/types"
import {setDefaults, translate} from './actions'

const stepToQuestionNumber = (items: AnyElement[], step: string) =>
  Math.max(0,items.findIndex(item => item.name === step))

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const templateRegex = /\{[^}]+\}/g

type Props = {
  customerId: string,
  formData: SurveyJSON,
  formName: string
}

export default class SurveyModel {
  sessionId = Date.now()
  @observable data : SurveyJSON;
  @observable formName : string;
  @observable currentQuestion: number
  @observable prevQuestion: number
  @observable values: any = {}
  @observable errors: {[key: string]: undefined | string} = {}
  @observable state: 'loading' | 'running' | 'submitting' | 'submitted' = 'loading'
  @observable customerId: string
  @observable touched: any = {}
  @observable locale: string
  @observable submitted = false
  @observable isConnectionOk = true

  constructor({customerId, formData, formName}: Props) {
    this.customerId = customerId
    this.formName = formName
    this.data = formData
    setDefaults(this)
    const step = this.items[0].name
    this.currentQuestion = stepToQuestionNumber(this.items, step)
    this.prevQuestion = this.currentQuestion
    this.locale = this.data.defaultLocale
  }

  @action setStep(step: string, initial?: boolean) {
    this.setCurrentQuestion(stepToQuestionNumber(this.items, step))
    if (initial)
      this.prevQuestion = this.currentQuestion
  }

  @action setCurrentQuestion(number: number) {
    this.prevQuestion = this.currentQuestion
    this.currentQuestion = number
  }

  @action setValues(data: any) {
    for (let key in data)
      if (this.hasItem(key))
        this.setValue(key, data[key])
  }

  @action setValue(name: string, value: any) {
    const question = this.findItem(name)
    if (!question)
      return

    this.values[name] = value
    this.touched[name] = true
    this.validateQuestion(question as Question)
  }

  @action removeValue(name: string) {
    this.values[name] = null
    this.touched[name] = true
  }

  @action toggleArrayValue(name: string, value: any) {
    let array = this.values[name]
    if (!Array.isArray(array)) {
      this.values[name] = []
      array = this.values[name]
    }

    const index = array.indexOf(value)
    index === -1 ? array.push(value) : array.splice(index, 1)
    this.setValue(name, array)
    return toJS(array)
  }

  @action validateQuestion(question: Question) {
    const {name} = question
    if (!name) return

    if (question.validate) { 
      this.errors[name] = question.validate(this.values);
    } else if (
      question.required &&
      (
        !this.values[name] ||
        (Array.isArray(this.values[name]) && this.values[name].length === 0)
      )
    ){
      this.errors[name] = this.data.errors.required
    } else if ((question as InputData).inputType === 'email'){
      this.errors[name] = emailRegex.test(this.values[name]) ? undefined : translate(this, 'invalidEmail');
    } else
      this.errors[name] = undefined
  }

  @action validate() {
    //try to calculate the tracking amount, but always fall back if it fails (potential error inside the user defined function)
    try {
      if(typeof this.data.calculateTrackingAmount === 'function')
        this.data.trackingAmount = this.data.calculateTrackingAmount(this.values)
    } catch (e) {
      this.data.trackingAmount = 0;
    }
    this.submitted = true
    let firstErrorQuestionNumber: undefined | number
    this.items.forEach((element, i) => {
      const {name} = element as Question
      if (name)
        this.validateQuestion(element as Question)

      if (firstErrorQuestionNumber === undefined && this.errors[name])
        firstErrorQuestionNumber = i
    })
    return firstErrorQuestionNumber
  }

  @computed get items() {
    return this.data.items
  }

  @computed get currentQuestionName() {
    return this.items[this.currentQuestion].name
  }

  @computed get nextDependenciesSatisfied() {
    if (this.currentQuestion === this.items.length - 1)
      return false

    const item = this.items[this.currentQuestion + 1]
    if (!item.dependencies)
      return true

    return item.dependencies.every(name => {
      return !!this.values[name]
    })
  }

  @computed get unavailableAnswers() {
    const unavailableAnswers: string[] = []
    const {values, items} = this
    for (let key in values) {
      const matchedItems = items.filter(item =>
        item.name === key && this.checkCondition(item)
      )
      if (matchedItems.length) {
        const allValid = matchedItems.every(item => {
          const {options} = (item as CheckboxesData)
          if (!options) return true

          const value = values[key]
          if (value === undefined || value === null)
            return true

          let matchedOptions: Option[] = []
          if ((item as CheckboxesData).multiple)
            matchedOptions = options.filter(option => value.includes(option.value))
          else
            matchedOptions = options.filter(option => option.value === value)
          const valid = matchedOptions.every(option =>
            this.checkCondition(option)
          )
          if (valid) return true
        })
        if (allValid)
          continue
      }

      unavailableAnswers.push(key)
    }
    return unavailableAnswers
  }

  findItem(name: string) {
    return this.items.find((item) =>
      (item as Question).name === name
    ) as AnyElement
  }

  hasItem(name: string) {
    return this.findItem(name) != undefined
  }

  nextQuestionNumber() {
    let num = this.currentQuestion + 1
    const len = this.items.length
    while (!this.isQuestionAvailable(num)) {
      num++
      if (num >= len) return
    }
    return num
  }

  prevQuestionNumber() {
    let num = this.currentQuestion - 1
    while (!this.isQuestionAvailable(num)) {
      num--
      if (num < 0) return
    }
    return num
  }

  updateData(item: AnyElement){
    if(item.type === 'payment'){
      this.calculateAmount((item as PaymentData))
    }
    let question : Question = (item as Question)
    question.title = (question.dynamicTitle) ? question.dynamicTitle(this.values) : question.title
  }

  isQuestionAvailable(i: number) {
    const item = this.items[i]
    if(item && !this.checkCondition(item)) return false;
    item && this.updateData(item)
    return item
  }

  checkCondition({if: condition}: {if?: Condition}) {
    if (!condition) return true

    if (typeof condition === 'function')
      return condition(this.values)

    const {name, op = 'equal', value} = condition
    const actual = this.values[name]

    if (op === 'equal')
      return actual === value
    else if (op === 'in')
      return value.includes(actual)
    else
      throw new Error(`Unknown operation in condition: ${JSON.stringify(condition)}`)
  }

  @action calculateAmount(data: PaymentData) {
    const {checkoutItems} = data;
    data._amount = 0;
    for (const key in checkoutItems) {
      if (Object.prototype.hasOwnProperty.call(checkoutItems, key)) {
        let checkoutItem : CheckoutItem = checkoutItems[key];
        if (checkoutItem.expression && typeof checkoutItem.expression === 'function')
          try {
            checkoutItem._value = checkoutItem.expression(this.values, data._amount);
          } catch (error) {
            console.log("Error calculating amount for " + checkoutItem.description + ": " + error) 
            checkoutItem._value = 0
          }
        else
          checkoutItem._value = 0;

        data._amount += checkoutItem._value
      }
    } 
  }
}