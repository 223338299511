import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Finshed',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Please fill this out'
  },
  locales: {
    dk: {
      nextButton: 'Continue',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Submit',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'competences',
      title: 'Which of the following fields do you have experience with?',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Bookkeeping',
          value: 'bookkeeping',
        }, {
          text: 'Bank reconciliation',
          value: 'reconciliation',
        }, {
          text: 'Programming in Spyder',
          value: 'fake',
        }, {
          text: 'Excel',
          value: 'excel',
        },
      ]
/*    }, {
      if: (values) => (values.competences),
      type: 'input',
      name: 'upwork_link',
      title: 'What is the link to your upwork profile page?',
      required: true,
      placeholder: 'https://www.upwork.com/freelancers/waseem63100',*/
    },{
      type: 'input',
      name: 'hours',
      title: 'How many hours are you able to work per week?',
      required: true,
      placeholder: '10',
      mask: '999',
    },{
      if: (values) => (values.competences && values.competences.indexOf('bookkeeping') >= 0 && values.competences.indexOf('reconciliation') >= 0 && values.competences.indexOf('excel') >= 0 && !(values.competences.indexOf('fake') >= 0) && values.hours && !isNaN(values.hours) && values.hours > 25 && values.hours < 65),
      type: 'checkboxes',
      name: 'test',
      title: 'Would you be willing to take a typing and bank reconciliation test that takes approximately 3 hours to complete. The test is hard and you will get paid $15 ONLY if you pass the test on your first attempt. If you complete the test successfully, then I can give you a lot of tasks',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Yes',
          value: 'want_test',
        }, {
          text: 'No',
          value: 'dont_want_test',
        }
      ]
/*    }, {
      type: 'input',
      name: 'upwork_name',
      title: 'What is your name on upwork',
      required: true,
      placeholder: 'David A',*/
    }, {
      if: (values) => (values.test && values.test === 'want_test'),
      type: 'custom',
      name: 'info',
      html: '<blockquote>If you bid exactly the amount of $5 on the upwork project, then I will accept your bid, and pay you immediately (well, I will check every few days). I will also send you the test. If you pass the test on your first attempt, then I will send you a bonus of $15 as promised.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      if: (values) => (!values.test || values.test === 'dont_want_test'),
      type: 'custom',
      name: 'info_only_bids_with_9',
      html: '<blockquote>I will only consider bids on Upwork where the bid amount ends with 9, since then I know that you have gone through this wizard.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Press submit</blockquote>',
      buttons: ['submit']
    }
  ]
}

export default json
