import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'competences',
      title: 'Markér hvad du har erfaring med',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Salg over telefon eller fysisk',
          value: 'sales',
        }, {
          text: 'Bogføring for små virksomheder',
          value: 'bookkeeping',
        }, {
          text: 'Jeg har selv drevet virksomhed og/eller forstår mig på regnskaber',
          value: 'entrepreneur',
        }, {
          text: 'Ingen erfaring, men er interesseret i at lære',
          value: 'no_experience',
        },
      ]
    },{
      type: 'checkboxes',
      name: 'daytime',
      title: 'Kan du arbejde i de danske dagtimer?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'daytime',
        }, {
          text: 'Nej',
          value: 'not_daytime',
        },
      ]
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: true,
      placeholder: 'email@email.com',
    }, {
      type: 'input',
      name: 'hours',
      title: 'Hvor mange timer ønsker du at arbejde per uge',
      required: false,
      placeholder: '40',
      mask: '999',
    },{
      type: 'checkboxes',
      name: 'commission',
      title: 'Forstår du at hvis du arbejder som sælger for os (i modsætning til arbejde som til eksempel bogholder), så er du 100% provisionslønnet. Samtidig er det vores opgave at sørge for at du får nok kundehenvendelser at tage fat i',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Provisionsløn er fint for mig',
          value: 'commission_ok',
        }, {
          text: 'Provisionsløn passer mig ikke',
          value: 'commission_not_ok',
        },
      ]
    }, {
      type: 'input',
      name: 'hourly',
      title: 'Hvis du arbejdede som freelancer (hvor du fakturerede os for dine timer), hvor mange kroner tager du i såfald per time (eks. evt. moms)?',
      required: false,
      placeholder: '100',
      mask: '999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    },{
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
