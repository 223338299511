import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  allowNewCustomer: true,
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'what_are_you_looking_for',
      title: 'Hvad kan Revisor1 hjælpe dig med?',
      required: true,
      options: [
        {
          text: 'Jeg ønsker et tilbud på en konkret ydelse lige nu',
          value: 'want_offer',
        }, {
          text: 'Jeg ønsker at læse om jer på jeres webside eller på TrustPilot',
          value: 'read_about',
        }, {
          text: 'Jeg har et spørgsmål',
          value: 'question',
        },
      ]
    }, {
      if: (values) => values.what_are_you_looking_for === 'read_about',
      type: 'checkboxes',
      name: 'link_to_read_more',
      title: 'Hvor ønsker du at læse?',
      required: false,
      options: [
        {
          text: 'Webside',
          link: 'http://revisor1.dk/'
        }, {
          text: 'TrustPilot',
          link: 'https://dk.trustpilot.com/review/revisor1.dk',
        },
      ]
    }, {
      if: (values) => values.what_are_you_looking_for === 'question',
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Skriv den email vi kan sende svaret til her',
      required: false,
      placeholder: 'email@email.com',
    }, {
      if: (values) => values.what_are_you_looking_for === 'question',
      type: 'textarea',
      name: 'question',
      title: 'Skriv dit spørgsmål her',
      required: false,
      placeholder: 'Skriv dit spørgsmål her',
    }, {
      if: (values) => values.what_are_you_looking_for === 'want_offer',
      title: 'Company Search (Not dipslayed)',
      type: 'companySearch',
      name: 'cvr_search',
      noCompanyText: 'Jeg har ingen virksomhed',
      placeholder: 'Skriv CVR number eller virksomhedsnavn her...',
      required: false,
    },{
      //https://www.screencast.com/t/Hvj9IgxfoB
      //What to do about PMV                 Personligt ejet Mindre Virksomhed
      //
      //if: ({cvr_search, what_are_you_looking_for}) => !(['A/S', 'P/S', 'I/S', 'K/S', 'EFO'].includes(cvr_search?.nyesteVirksomhedsformKortBeskrivelse) && what_are_you_looking_for === 'want_offer'),
      if: (values) => values.what_are_you_looking_for === 'want_offer',
      type: 'checkboxes',
      name: 'what',
      title: 'Hvad kan vi hjælpe dig med?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Jeg ønsker at Revisor1 bogfører, udarbejder moms- og årsregnskab og laver alle indberetninger',
          value: 'all_inclusive',
        }, {
          text: 'Jeg har bogført selv, men trænger hjælp til at afslutte det (3.295 kroner)',
          value: 'accounting_help',
        }, {
          if: ({cvr_search}) => ['APS', 'IVS', 'A/S'].includes(cvr_search?.nyesteVirksomhedsformKortBeskrivelse),
          text: 'Selskabet er et holdingselskab (3.500 kroner)',
          value: 'holding',
        },
      ]
    }, {

      if: (values) => (values.what === 'accounting_help'),
      type: 'checkboxes',
      name: 'can_we_trust_the_bookkeeping',
      title: 'Hvor meget stoler du på at bogføringen er korrekt og komplet?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Jeg har, eller kommer til at have, bogført og afstemt det hele og i kan stole på tallene når i laver årsafslutning',
          value: 'correct_bookkeeping',
        }, {
          text: 'Jeg har stort sæt bogført det hele, men trænger hjælp til nogle ting',
          value: 'incomplete_bookkeeping',
        },
      ]
    }, {
      if: (values) => (values.can_we_trust_the_bookkeeping === 'incomplete_bookkeeping'),
      type: 'checkboxes',
      name: 'want_help_with_bookkeeping',
      title: 'For 500 kroner kan vi bruge én time på at kigge på din bogføring sammen med dig, for at se om den kan komme på plads. ' +
          'Hvis det lykkes, koster det typisk 3.295 kroner at lave en årsafslutning. Alternativt koster det kun en smule mere (fra 5.995 kroner) hvis vi skal bogføre det hele for dig',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Så ønsker jeg heller at i bogfører for mig',
          value: 'all_inclusive',
        }, {
          text: 'Jeg vil gerne betale 500 kroner for at i bruger én time på at kigge på min bogføring sammen med mig',
          value: 'pay_to_review',
        },
      ]
    }, {
      if: ({cvr_search, want_help_with_bookkeeping}) => (['ENK', 'I/S'].includes(cvr_search?.nyesteVirksomhedsformKortBeskrivelse) && (want_help_with_bookkeeping !== 'pay_to_review')),
      type: 'checkboxes',
      name: 'vso',
      title: 'Er virksomheden på Virksomhedsskatteordningen, hvilket er en speciel ordning som nogle få virksomheder tilmelder sig',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'vso_yes',
        },
        {
          text: 'Nej',
          value: 'vso_no',
        },
        {
          text: 'Ved ikke',
          value: 'vso_unknown',
        },
      ]
    }, {
      if: (values) => (values.vso === 'vso_yes') && (values.want_help_with_bookkeeping !== 'pay_to_review'),
      type: 'custom',
      name: 'vso_notice',
      html: '<blockquote>Vi tager 1.500 kroner ekstra om året for at lave den beregning der skal laves når du er på virksomhedsskatteordning, men vi kan desværre ikke tilbyde dette uden at vi laver bogføringen</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      if: (values) => (values.vso === 'vso_unknown'),
      type: 'custom',
      name: 'vso_notice',
      html: '<blockquote>Det er meget sjeldent at virksomhedder er på virksomhedsskatteordningen, så vi går ud fra at du ikke er det. Hvis det senere viser sig at du alligevel er det, så koster det blot 1.500 kroner ekstra.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      if: (values) => (values.what_are_you_looking_for === 'want_offer' && (values.what === 'all_inclusive' || values.want_help_with_bookkeeping === 'all_inclusive' || values.what === 'holding' || values.want_help_with_bookkeeping === 'holding')),
      type: 'checkboxes',
      name: 'complications',
      title: 'Vælg dem der passer',
      required: false,
      multiple: true,
      options: [
        {
          text: 'Virksomheden ejer ejendomme',
          value: 'owns_properties',
        },
        {
          text: 'Virksomheden handler med aktier',
          value: 'trades_with_stocks',
        },
        {
          if: (values) => (values.what !== 'holding' && values.want_help_with_bookkeeping !== 'holding'),
          text: 'Virksomheden betaler lønsumsafgift, hvilket er tilfælde for nogle virksomheder der ikke betaler moms',
          value: 'lønsum',
        },
      ]
    },{
      if: (values) =>  (values.complications && values.complications.includes('owns_properties')),
      type: 'custom',
      name: 'property_notification',
      html: '<blockquote>Ejendomme kan nogle gange gøre regnskabet mere kompliceret. Derfor vil vi gerne ringe dig og høre nærmere.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    }, {
      if: (values) => (values.complications && values.complications.includes('trades_with_stocks') && !(values.complications.includes('owns_properties'))),
      type: 'checkboxes',
      name: 'stock_trading_platform',
      title: 'Vi tager 1.500 kroner ekstra for at håndtere aktieinvesteringer. Hvilke platformer handler du aktier igennem?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Nordnet, Saxobank, Danske Bank eller Jyske Bank',
          value: 'approved_stock_brokers',
        }, {
          text: 'Igennem andre mæglere/banker',
          value: 'other_stock_brokers',
        }
      ]
    }, {
      if: (values) => (values.stock_trading_platform === 'other_stock_brokers'),
      type: 'custom',
      name: 'unknown_trading_platforms',
      html: '<blockquote>Vi kan i de allerfleste tilfælde hjælpe dig selv om du handler aktier gennem andre platforme, eller køber kapitalandele i selskaber, men lade os få en onboarder til at ringe til dig for at høre nærmere</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
      /*Goto, we need to contact you*/
    }, {
      if: (values) =>  (values.complications && values.complications.includes('lønsum') && !(values.stock_trading_platform === 'other_stock_brokers') && !(values.complications.includes('owns_properties'))),
      type: 'checkboxes',
      name: 'lønsum_and_moms',
      title: 'Udover lønsum, sælger du også varer/ydelser hvor du opkræver moms?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'splitmoms',
        },
        {
          text: 'Nej',
          value: 'not_splitmoms',
        },
      ]
    }, {
      if: (values) => (values.lønsum_and_moms === 'splitmoms'),
      type: 'custom',
      name: 'lønsum_and_employees_notice',
      html: '<blockquote>Når du betaler både lønsum og moms koster det 1500 kroner eks. moms. ekstra</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    }, {
      if: (values) =>  (values.complications && values.complications.includes('lønsum') && !(values.stock_trading_platform === 'other_stock_brokers') && !(values.complications.includes('owns_properties'))),
      type: 'checkboxes',
      name: 'lønsum_employees',
      title: 'Udover dig selv, har du, eller får du snart medarbejdere i din virksomhed?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'lønsum_and_employees',
        },
        {
          text: 'Nej',
          value: 'lønsum_but_no_employees',
        },
      ]
    }, {
      if: (values) => (values.lønsum_employees === 'lønsum_and_employees'),
      type: 'custom',
      name: 'lønsum_and_employees_notice',
      html: '<blockquote>Når du betaler lønsum og har medarbejdere er der indberetningsfrist på blot 14 dage. Vi kan godt løse det og det koster ikke ekstra, men vi vil gerne tale med dig og beskrive detaljerne rundt dette.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    }, {
      if: (values) =>  ((values.what === 'all_inclusive' || values.want_help_with_bookkeeping === 'all_inclusive') && !(values.stock_trading_platform === 'other_stock_brokers') && !(values.lønsum_employees === 'lønsum_and_employees') && !(values.complications && values.complications.includes('owns_properties'))),
      type: 'checkboxes',
      name: 'how_much_turnover',
      title: 'Hvor meget forventer du at omsætte for i regnskabsåret vi skal hjælpe dig med',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Mindre end 200.000 kroner',
          value: '0',
        }, {
          text: 'Mellem 200.000 og 500.000 kroner',
          value: '3000',
        }, {
          text: 'Mellem 500.000 og 1.000.000 kroner',
          value: '4500',
        }, {
          text: 'Mellem 1.000.000 og 2.500.000 kroner',
          value: '5500',
        }, {
          text: 'Mere end 2.500.000 kroner',
          value: '6500',
        },
      ]
    }, {
      if: (values) => (values.what_are_you_looking_for !== 'question' && !(values.lønsum_employees === 'lønsum_and_employees' || values.stock_trading_platform === 'other_stock_brokers' || (values.complications && values.complications.includes('owns_properties')))),
      type: 'payment',
      name: 'checkout',
      title: 'Tilbud',
      required: false,
      currency: 'dkk',
      description: 'Revisor1',
      checkoutItems: [
        {
          description: "Pris eksklusive moms",
          expression: (values, subtotal) => {
            let amount = 0
            if (values.can_we_trust_the_bookkeeping === 'correct_bookkeeping')
              amount = 3295
            else if (values.what === 'all_inclusive' || values.want_help_with_bookkeeping === 'all_inclusive') {
              amount = 5995
              if (['APS', 'IVS', 'A/S'].includes(values.cvr_search?.nyesteVirksomhedsformKortBeskrivelse))
                amount += 1000
              if (values.how_much_turnover && !isNaN(values.how_much_turnover))
                amount += Number.parseInt(values.how_much_turnover)
            }
            else if (values.want_help_with_bookkeeping === 'pay_to_review')
              amount = 500
            else if (values.what === 'holding' || values.want_help_with_bookkeeping === 'holding')
              amount = 3500
            return amount
          },
        },{
          description: "Virksomhedsskatteordning",
          expression: (values) => {
            let amount = 0
            if(values.vso === 'vso_yes')
              amount = 1500
            return amount
          },
        },{
          description: "Både indrapportering af lønsum og moms",
          expression: (values) => {
            let amount = 0
            if(values.lønsum_and_moms === 'splitmoms')
              amount = 1500
            return amount
          },
        },{
          description: "Aktiehandler",
          expression: (values) => {
            let amount = 0
            if(values.complications.includes('trades_with_stocks'))
              amount = 1500
            return amount
          },
        },{
          description: "Moms",
          expression: (values, subtotal) => {
            return subtotal * 0.25
          },
        },
      ]
    }, {
      if: (values) => (values.lønsum_employees === 'lønsum_and_employees' || values.stock_trading_platform === 'other_stock_brokers' || (values.complications && values.complications.includes('owns_properties'))),
      type: 'input',
      name: 'oneLineAnswer',
      title: '',
      dynamicTitle: (values) => ((1 == 1) ?
          "Vi vil gerne ringe og høre hvordan vi kan hjælpe dig." :
          "Hvad er dit telefonnummer?"),
      required: false,
      placeholder: 'Skriv dit telefonnummer her...',
    },{
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Tusind tak!</blockquote>',
      buttons: ['submit']
    },

  ]
}

export default json
