import React from 'react'
import Survey from 'Survey/Model'
import Fieldset from "Survey/Fieldset/Fieldset"
import {observer} from "mobx-react"
import {TextAreaData} from 'Survey/types'
import {setValue} from 'Survey/actions'
import style from './style.module.scss'

type Props = {
  number: number
  model: Survey
  data: TextAreaData
}

export default observer(function TextAreaComponent ({number, model, data: {title, name, required, placeholder}}: Props)  {
  const el = React.useRef(null)

  const adjustHeight = (el: HTMLTextAreaElement) => {
    el.style.height = ''
    el.style.height = `${el.scrollHeight}px`
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!e.shiftKey || e.key !== 'Enter')
      e.stopPropagation()
  }

  React.useEffect(() => {
    const textarea = el.current as unknown as HTMLTextAreaElement
    adjustHeight(textarea)
    textarea.addEventListener('keydown', handleKeyDown, true)
  }, [])

  const handleChange = ({target}: {target: HTMLTextAreaElement}) => {
    adjustHeight(target)
    setValue(model, name, target.value)
  }

  return <Fieldset number={number} title={title} model={model} name={name} required={required}>
    <textarea
      ref={el}
      className={style.textarea}
      placeholder={placeholder}
      onChange={handleChange}
      value={model.values[name]}
    />
  </Fieldset>
})
