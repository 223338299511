import {action, observable} from "mobx";

export default class CompanySearchState {
  @observable search = ''
  @observable state: 'init' | 'loading' | 'results' = 'init'
  @observable companies?: any[]

  @action emptySearch() {
    this.search = ''
    this.companies = []
    this.state = 'init'
  }

  @action setSearch(search: string) {
    this.search = search
    this.state = 'loading'
  }

  @action setCompanies(companies: any[]) {
    this.companies = companies
    this.state = 'results'
  }
}