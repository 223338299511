import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  allowNewCustomer: true,
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'how_much',
      title: 'How much do you wanna pay?',
      required: true,
      options: [
        {
          text: '300 DKK',
          value: '300',
        },
        {
          text: '500 DKK',
          value: '500',
        },
        {
          text: '800 DKK',
          value: '800',
        },
      ]
    },
    {
      if: (values) => values.how_much < 800, 
      type: 'checkboxes',
      name: 'add_extra_20',
      //THIS DOES NOT WORK how_much does not get replaced
      title: 'Looks like {how_much} is not much, do you want to add 20%?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'yes_add_extra',
        },
        {
          text: 'Nej',
          value: 'no_add_extra',
        }
      ]
    },
    {
      type: 'input',
      name: 'extra_cash',
      title: 'What about a little extra?',
      required: true,
      mask: '99.99',
      placeholder: 'Enter the amount here...',
      validate: (values) => {
        return (isNaN(values.extra_cash) ? 'We need a number here!' : '')
      }
    },
    {
      type: 'payment',
      name: 'checkout_test',
      title: 'Let\'s try to make a payment ',
      required: false,
      currency: 'dkk',
      amount: 3000.0,
      description: 'Checkout item text description',
      amountExpression: (values) => { 
        let amount = Number.parseFloat(values.how_much)
        if(values.add_extra_20 == 'yes_add_extra')
          amount *= 1.2
        if(!isNaN(values.extra_cash))
          amount += Number.parseFloat(values.extra_cash)

        return amount
      }
    },
    {
      type: 'bankLogin',
      name: 'viiaBankLogin',
      title: 'Bank account access',
      required: true,
      
    },
  ]
}

export default json
