import React, { Component } from 'react'
import SurveyModel from 'Survey/Model'
import {CustomData, ElementType, InputData, TextAreaData, CheckboxesData, RateData, CompanySearchData, PaymentData, BankLoginData} from 'Survey/types'
import Swipe from "./Swipe/Swipe"
import {nextQuestion, prevQuestion} from "./actions"
import Screen from "./Screen/Screen"
import Input from "./Elements/Input/Input"
import TextArea from "./Elements/TextArea/TextArea"
import Checkboxes from "./Elements/Checkboxes/Checkboxes"
import Rate from "./Elements/Rate/Rate"
import Custom from "./Elements/Custom/Custom"
import BankLogin from "./Elements/BankLogin/BankLogin"
import CompanySearch from "./Elements/CompanySearch/CompanySearch"
import Payment from "./Elements/Payment/Payment"
import SuccessPage from './SuccessPage/SuccessPage'
import {observer} from 'mobx-react'
import Pagination from './Pagination/Pagination'
import style from './style.module.scss'
import NotFound from "NotFound/NotFound";
import CustomError from "CustomError/CustomError";
import KeyboardEvents from "./KeyboardEvents"
import ConnectWebsocket from "./ConnectWebsocket"
import HistoryListener from "./HistoryListener"
import InternetLoss from "./InternetLoss/InternetLoss";
import {SurveyJSON} from "Survey/types"
import {createCustomer} from './api'
import Cookies from 'universal-cookie';


const Survey = observer(({model}: {model: SurveyModel}) => {
  if (!model.isConnectionOk)
    return <InternetLoss />

  if (model.state === 'loading')
    return null

  if (model.state === 'submitted')
    return <SuccessPage model={model} />

  let visibleNumber = 0

  return <Swipe prev={() => prevQuestion(model)} next={() => nextQuestion(model)}>
    <React.Fragment>
      {model.items.map((data, i) => {
        const {type} = data

        if (model.isQuestionAvailable(i))
          visibleNumber++

        return <Screen key={i} number={i} model={model}>
          <div className={style.container}>
            {type === ElementType.input && <Input number={visibleNumber} model={model} data={data as InputData} />}
            {type === ElementType.textarea && <TextArea number={visibleNumber} model={model} data={data as TextAreaData} />}
            {type === ElementType.checkboxes && <Checkboxes number={visibleNumber} model={model} data={data as CheckboxesData} />}
            {type === ElementType.rate && <Rate number={visibleNumber} model={model} data={data as RateData} />}
            {type === ElementType.custom && <Custom model={model} data={data as CustomData}/>}
            {type === ElementType.companySearch && <CompanySearch model={model} data={data as CompanySearchData}/>}
            {type === ElementType.payment && <Payment number={visibleNumber} model={model} data={data as PaymentData}/>}
            {type === ElementType.bankLogin && <BankLogin number={visibleNumber} model={model} data={data as BankLoginData}/>}
          </div>
        </Screen>
      })}
    </React.Fragment>
    <Pagination model={model} />
  </Swipe>
})

interface IProps {

}

interface IState {
  formName: string
  customerId: string
  formData?: SurveyJSON,
  newCustomer: boolean
}

export class SurveyComponent extends React.Component<IProps, IState>  {
  _cookies : Cookies = new Cookies();
  _cookieExpirationDate : Date = (() => {let d : Date = new Date(); d.setFullYear(d.getFullYear() + 1); return d;})()


  constructor(props: IProps) {
    super(props);
    this.state = {
      customerId: '',
      formName: '',
      newCustomer: false
    }
  }

  componentDidMount(){
    if(!this.state.formName) return;

    try {
      const formData: SurveyJSON = require("SurveyData/SurveyData." + this.state.formName + ".ts").default
      this.setState({formData: formData})
      const matchCustomer = window.location.search.match(/\bcustomer_id=([^&]+)/)
        if (matchCustomer){
          this._cookies.set('customerId', matchCustomer[1], { path: '/', expires: this._cookieExpirationDate });
          this.setState({customerId: matchCustomer[1]})
        } else {
          const cookieCustomerId = this._cookies.get<string>('customerId')
          if(cookieCustomerId != undefined && cookieCustomerId){
            this.setState({customerId: cookieCustomerId})
          } else {
            if(formData.allowNewCustomer) {
              createCustomer().then(({customerId}) => {
                this.setState({newCustomer: true, customerId: customerId})
              }).catch((error) => {
                console.log(error)
                this.setState({newCustomer: true, customerId: 'internal-error'})
              })
            } else {
              this.setState({newCustomer: false, customerId: 'error'})
            }
          }
        }
    } catch (error) {
      this.setState({formData: undefined})
    }
  }

  componentWillMount(){
    const matchForm = window.location.search.match(/\bform=([a-z,A-Z,0-9]+)/)

    if (matchForm){
      this._cookies.set('formName', matchForm[1], { path: '/', expires: this._cookieExpirationDate});
      this.setState({formName: matchForm[1]})
    } else {
      const cookieFormName = this._cookies.get<string>('formName')
      if(cookieFormName)
        this.setState({formName: cookieFormName})
    }

    

  }

  render () {
    
    if (!this.state.formName)
      return <CustomError code="412" title="Missing form name." />

    if (!this.state.formData)
      return <CustomError code="404" title="Form data not found." />

    if (!this.state.customerId)
      return <CustomError code="OK" title="The form is being prepared." />

    if (this.state.customerId == 'error')
      return <CustomError code="412" title="A customer is required." />

    if (this.state.customerId == 'internal-error')
      return <CustomError code="500" title="Unable to process your request." />  

    if (this.state.newCustomer)
      window.location.href=`/?customer_id=${this.state.customerId}&form=${this.state.formName}`
      
    
    try {
      const model = new SurveyModel({customerId: this.state.customerId, formData: this.state.formData, formName: this.state.formName})
      return <KeyboardEvents model={model}>
        <HistoryListener model={model}>
          <ConnectWebsocket model={model}>
            <Survey model={model}/>
          </ConnectWebsocket>
        </HistoryListener>
      </KeyboardEvents>
    } catch (error) {
      this._cookies.remove('formName')
      this._cookies.remove('customerId')
      return <NotFound />
    }

  }
}
