import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'

type Size = 'xl' | 'l' | 'm' | 's' | 'xs'

export default function FontComponent (
  {size, className, ...props}: any & {size: Size, className?: string}
) {
  return <span className={cn(style[size], className)} {...props} />
}
