import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'competences',
      title: 'Markér de opgaver du kunne ønske at arbejde med',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Arbejde med bogholderi',
          value: 'bookkeeping',
        }, {
          text: 'Lave revisor arbejde',
          value: 'accounting',
        }, {
          text: 'Sælge vores ydelser til potentielle kunder, efter at de har bedt os om at kontakte dem',
          value: 'onboarding',
        }, {
          text: 'Kundeservice, dvs. ringe til kunder der har brug for hjælp eller potentielt er sure over noget',
          value: 'customer_service',
        }, {
          text: 'Arbejde med markedsføring, reklamer, websider',
          value: 'marketing',
        },
      ]
    }, {
      if: (values) => (values.competences && (values.competences.indexOf('marketing') >= 0)),
      type: 'input',
      name: 'experience_with_advertising',
      title: 'Hvilken erfaring har du med facebook og google reklamer?',
      required: false,
      placeholder: 'Ingen erfaring',
    },{
      if: (values) => (values.competences && (values.competences.indexOf('marketing') >= 0)),
      type: 'input',
      name: 'experience_with_graphics',
      title: 'Er du i stand til at lave grafiske udtryk?',
      required: false,
      placeholder: 'Nej',
    },{
      if: (values) => (values.competences && (values.competences.indexOf('customer_service') >= 0 || values.competences.indexOf('onboarding') >= 0)),
      type: 'input',
      name: 'daytime',
      title: 'Kan du arbejde i danske dagtimer?',
      required: false,
      placeholder: 'Frem til 14.00 og igen nogle aftener',
    },{
      if: (values) => (values.competences && (values.competences.indexOf('customer_service') >= 0 || values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('accounting') >= 0 || values.competences.indexOf('bookkeeping') >= 0)),
      type: 'input',
      name: 'hours',
      title: 'Hvor mange timer ønsker du at arbejde per uge i vores højsæson der går fra 1. januar til 15. juli',
      required: false,
      placeholder: '40',
      mask: '999',
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: false,
      placeholder: 'email@email.com',
    }, {
      type: 'input',
      name: 'hourly',
      title: 'Vi starter typisk op med at du fakturerer os for dine timer. Hvor mange danske kroner tager du i såfald per time (eks. evt. moms)?',
      required: true,
      placeholder: '100',
      mask: '999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    },{
      if: (values) => (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0)),
      type: 'textarea',
      name: 'test_question',
      title: 'På hvilke måder kan man kontere et besøg i en bar?',
      required: false,
      placeholder: 'Skriv dit svar her',
    },{
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
