import React from 'react'
import Model from 'Survey/Model'
import {observer} from "mobx-react";

type Props = {
  model: Model
  text: string
}

export default observer(function TextComponent ({model, text}: Props) {
  return <React.Fragment>
    {text.replace(/%{\w+}/g, (string) =>
      model.values[string.slice(2, -1)]
    )}
  </React.Fragment>
  }
)
