import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import {BankLoginData} from 'Survey/types'
import style from './style.module.scss'
import Model from 'Survey/Model'
import Fieldset from "Survey/Fieldset/Fieldset"
import {setValue} from 'Survey/actions'
import {observer} from "mobx-react"
import { checkViiaAccess, getViiaLoginLink } from 'Survey/api'
import { Link } from 'react-router-dom'

type Props = {
  data: BankLoginData
  model: Model,
  number: number,
}



export default observer(function BankLoginComponent({model, number, data: {title, name, loginExistsText, loginRequiredText, required}}: Props) {

  const [viiaStatus, setViiaStatus] = useState(false);
  const [viiaStatusLoaded, setViiaStatusLoaded] = useState(false);
  const [viiaLoginLink, setViiaLoginLink] = useState("");

  const match = window.location.search.match(/\bsuccess=1([^&]+)/)
  if (match && !model.values[name]){
    setValue(model, name, true)
  }
  

  useEffect(() => {
    checkViiaAccess(model.customerId)
    .then(({viiaAccess} : {viiaAccess : boolean}) => {
      setViiaStatus(viiaAccess)
      if(!viiaAccess){
        getViiaLoginLink(model.customerId).then(({linkUrl} : {linkUrl : string}) => {
          setViiaLoginLink(linkUrl + "?returnUrl=" + window.location)
        })
      }
    }
    );
   }, [model.customerId])

  const loginExists = (<div><span className={style.simpleText}> {loginExistsText || "Found viia credentials" } </span> <br/></div>);


  return <Fieldset number={number} title={title} model={model} name={name} required={required}>    
  <React.Fragment>
    {(viiaStatus && loginExists)}
    {viiaLoginLink && 
    (
      <div>
        <span className={style.simpleText}> {loginRequiredText || "We need your authorization to proceed"} </span> <br/>
        <a href={viiaLoginLink}>
          <button className={style.button} role="link" >
            Bank login
          </button>
        </a>
      </div>)
    }
  </React.Fragment>
  </Fieldset>
})
