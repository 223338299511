import React from 'react'
import Survey from 'Survey/Model'
import State from '../state'
import {observer} from "mobx-react"
import Font from "Survey/Shared/Font/Font"
import Checkbox from 'Survey/Shared/Checkbox/Checkbox'
import style from './style.module.scss'
import {companyText} from "../utils"

type Props = {
  model: Survey
  name: string
  state: State
}

export default observer(function ListComponent ({model, name, state}: Props) {
  if (!state.companies)
    return null

  if (state.companies.length === 0)
    return <div className={style.notFound}><Font size='l'>Could not find a company for your request</Font></div>

  const value = model.values[name]

  return <div className={style.list}>
    {state.companies.map(company => {
      return <Checkbox
        key={company.cvr}
        model={model}
        name={name}
        checked={value ? value.cvr === company.cvr : false}
        option={{
          text: companyText(company),
          value: company,
        }}
      />
    })}
  </div>
})
