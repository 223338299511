import React from 'react'
import style from './style.module.scss'
import {CheckboxesData} from 'Survey/types'
import Fieldset from 'Survey/Fieldset/Fieldset'
import Model from 'Survey/Model'
import Checkbox from 'Survey/Shared/Checkbox/Checkbox'
import {observer} from "mobx-react"

type Props = {
  number: number
  model: Model
  data: CheckboxesData
}

export default observer(function CheckboxesComponent ({number, model, data: {title, required, name, multiple, options, text}}: Props)  {
  return <Fieldset number={number} title={title} model={model} name={name} required={required}>
    { text && <blockquote dangerouslySetInnerHTML={{__html:text}}></blockquote>}
    <div className={style.wrap}>
      {options.map((option, i) =>
        model.checkCondition(option) && <Checkbox key={i} model={model} name={name} multiple={multiple} option={option} />
      )}
    </div>
  </Fieldset>
})
