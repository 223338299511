import React from 'react'
import {observer} from "mobx-react"
import cn from 'classnames'
import Survey from 'Survey/Model'
import {CompanySearchData} from 'Survey/types'
import State from './state'
import SearchInput from "./SearchInput/SearchInput"
import List from './List/List'
import Loader from './Loader/Loader'
import Checkbox from 'Survey/Shared/Checkbox/Checkbox'
import style from './style.module.scss'
import {companyText} from "./utils"
import {toJS} from "mobx"

type Props = {
  model: Survey
  data: CompanySearchData
}

export default observer(function CompanySearchComponent({model, data: {name, noCompanyText, placeholder}}: Props)  {
  const [state] = React.useState(new State())
  const selected = model.values[name]

  return <div className={cn(style.wrap, state.state !== 'init' && style.moveUp)}>
    <SearchInput state={state} placeholder={placeholder} />
    {state.state === 'init' && selected &&
      <Checkbox
        className={style.checkbox}
        checked={true} model={model} name={name} option={{text: companyText(selected), value: toJS(selected)}}
      />
    }
    {state.state === 'init' &&
      <Checkbox
        className={style.checkboxNoCompany} model={model} name={name} option={{text: noCompanyText, value: ''}}
      />
    }
    {state.state === 'loading' && <Loader />}
    {state.state === 'results' && <List model={model} name={name} state={state} />}
  </div>
})
