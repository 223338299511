import React from 'react'

const touchThreshold = 100
const swipeTimout = 300

type Props = {
  prev: () => any
  next: () => any
  children: React.ReactNode
}

const isScrollActive = (isUp: boolean, ref: HTMLElement, el: HTMLElement | null) => {
  while (el && el !== ref) {
    if (el.scrollHeight > el.clientHeight)
      if (isUp ? el.scrollTop > 0 : el.scrollTop + el.clientHeight < el.scrollHeight)
        return true

    el = el.parentNode as HTMLElement
  }
}

const getTouchSwipeDirs = (ref: HTMLElement, el: HTMLElement) => {
  let up = true
  let down = true
  while (el && el !== ref) {
    if (el.scrollHeight > el.clientHeight) {
      if (el.scrollTop > 0)
        up = false
      if (el.scrollTop + el.clientHeight < el.scrollHeight)
        down = false
    }

    el = el.parentNode as HTMLElement
  }
  return [up, down]
}

export default function SwipeComponent ({prev, next, children}: Props) {
  const ref = React.useRef(null)

  let swipedUp = 0
  let swipedDown = 0
  let enableSwipeUp = false
  let enableSwipeDown = false
  let upTimeout = setTimeout(() => {
    enableSwipeUp = true
  }, swipeTimout) as any
  let downTimeout = setTimeout(() => {
    enableSwipeDown = true
  }, swipeTimout) as any
  let prevY = 0

  const handleWheel: React.WheelEventHandler = (e) => {
    const y = e.deltaY
    let skip = false
    const isUp = y < 0
    if (!isUp && y <= prevY || isUp && y >= prevY)
      skip = true
    prevY = y
    if (skip) {
      swipedUp = 0
      swipedDown = 0
      return
    }

    if (isScrollActive(isUp, ref.current as unknown as HTMLElement, e.target as HTMLElement))
      return

    if (isUp) {
      clearTimeout(upTimeout)
      upTimeout = setTimeout(() => {
        enableSwipeUp = true
      }, swipeTimout) as any
    } else {
      clearTimeout(downTimeout)
      downTimeout = setTimeout(() => {
        enableSwipeDown = true
      }, swipeTimout) as any
    }

    if ((isUp ? !enableSwipeUp : !enableSwipeDown) || (y > -5 && y < 5))
      return

    if (isUp)
      swipedUp += e.deltaY
    else
      swipedDown += e.deltaY

    if (!isUp && swipedDown > 50) {
      enableSwipeDown = false
      next()
    } else if (isUp && swipedUp < -50) {
      enableSwipeUp = false
      prev()
    }
  }

  let mouseDownY = 0
  let touchSwipeDirs = [false, false]
  const handleTouchStart = (e: React.TouchEvent) => {
    mouseDownY = e.touches[0].pageY
    touchSwipeDirs = getTouchSwipeDirs(
      ref.current as unknown as HTMLElement, e.target as HTMLElement
    )
  }

  const handleTouchEnd = (e: React.TouchEvent) => {
    const delta = e.changedTouches[0].pageY - mouseDownY
    const [up, down] = touchSwipeDirs
    if (delta >= touchThreshold && up)
      prev()
    else if (delta <= -touchThreshold && down)
      next()
  }

  return <div
    ref={ref}
    onWheel={handleWheel}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
  >
    {children}
  </div>
}
