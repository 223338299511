import React from 'react'
import {observer} from 'mobx-react'
import Font from 'Survey/Shared/Font/Font'
import style from './style.module.scss'
import Model from 'Survey/Model'
import {nextQuestion, setValue} from 'Survey/actions'

type Props = {
  number: number
  model: Model
  name: string
}

export default observer(function OptionsComponent ({number, model, name}: Props) {
  const handleChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) =>
    setValue(model, name, parseInt(value), true)

  return <label className={style.optionWrap}>
    <input
      type='radio'
      className={style.input}
      name={name}
      value={number}
      checked={number === model.values[name]}
      onChange={handleChange}
    />
    <Font size='m' className={style.option}>{number}</Font>
  </label>
})
