import React from 'react'
import {CustomData} from 'Survey/types'
import style from './style.module.scss'
import Font from 'Survey/Shared/Font/Font'
import Model from 'Survey/Model'
import Buttons from 'Survey/Elements/Buttons/Buttons'

type Props = {
  data: CustomData
  model: Model
}

export default function CustomComponent({model, data: {html, buttonsAlign, buttons}}: Props) {
  return <div className={style.wrap}>
    {html && <Font size='xl' className={style.content} dangerouslySetInnerHTML={{__html: html}}/>}
    {buttons && <Buttons model={model} buttonsAlign={buttonsAlign} buttons={buttons}/>}
  </div>
}
