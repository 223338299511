import React from 'react'
import style from './style.module.scss'
import Model from 'Survey/Model'
import Font from 'Survey/Shared/Font/Font'
import ReactPixel from 'react-facebook-pixel'

ReactPixel.init('1324729324292440')

export default function SuccessPageComponent ({model: {data: {success, trackingAmount}, state, submitted}}: {model: Model}) {
  //make sure we have just successfully submitted the form for the first time to avoid false tracking on facebook
  if(state == 'submitted' && submitted && trackingAmount){
    ReactPixel.track('Purchase', {currency: "DKK", value: trackingAmount});
  }
  return <div className={style.page}><Font size='xl'>{success}</Font></div>
}
