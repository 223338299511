import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'what_are_you_looking_for',
      title: 'Hvad kan Revisor1 hjælpe dig med?',
      required: true,
      options: [
        {
          text: 'Jeg ønsker et tilbud på en konkret ydelse lige nu',
          value: 'want_offer',
        },
        {
          text: 'Jeg ønsker at læse om jer på jeres webside eller på TrustPilot',
          value: 'read_about',
        },
        {
          text: 'Jeg har et spørgsmål',
          value: 'question',
        },
      ]
    },
    {
      if: (values) => values.what_are_you_looking_for === 'read_about',
      type: 'checkboxes',
      name: 'link_to_read_more',
      title: 'Hvad kan Revisor1 hjælpe dig med?',
      required: true,
      options: [
        {
          text: 'Webside',
          link: 'http://revisor1.dk/'
        },
        {
          text: 'TrustPilot',
          link: 'https://dk.trustpilot.com/review/revisor1.dk',
        },
      ]
    },
    {
      if: (values) => values.what_are_you_looking_for === 'question',
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Skriv den email vi kan sende svaret til her',
      required: true,
      placeholder: 'email@email.com',
    },
    {
      if: (values) => values.what_are_you_looking_for === 'question',
      type: 'textarea',
      name: 'question',
      title: 'Skriv dit spørgsmål her',
      required: true,
      placeholder: 'Skriv dit spørgsmål her',
    },
    {
      if: (values) => values.what_are_you_looking_for === 'want_offer',
      type: 'companySearch',
      title: 'Company Search (not displayed)',
      name: 'cvr_search',
      noCompanyText: 'Jeg har ingen virksomhed',
      placeholder: 'Skriv dit CVR number eller virksomhedsnavn her...',
      required: true,
    },
    /*{
      if: (values) => values.what_are_you_looking_for === 'want_offer',
      type: 'input',
      name: 'cvr_search',
      title: 'Hvis du søger frem én af virksomhederne dine, kan vi fortælle hvad vi kan gøre for lige præsis den',
      required: true,
      placeholder: 'CVR number searching here',
    },
    {
      type: 'custom',
      name: 'insertCVR',
      html: '<blockquote>Please insert your CVR number. Requires the integration of this: https://uw02-nodejs-searchbox.herokuapp.com/</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next'],
    },*/
    {
      if: (values) => values.what_are_you_looking_for === 'want_offer',
      type: 'checkboxes',
      name: 'what',
      title: 'Hvad kan vi hjælpe dig med?',
      required: true,
      multiple: true,
      options: [
        {
          if: ({cvr_search}) => ['ENK', 'I/S'].includes(cvr_search?.nyesteVirksomhedsformKortBeskrivelse),
          text: 'Udvidede selvangivelse',
          value: 'udvidede_selvangivelse',
        },
        {
          if: ({cvr_search}) => ['APS', 'IVS', 'A/S'].includes(cvr_search?.nyesteVirksomhedsformKortBeskrivelse),
          text: 'Udarbejdelse/indberetning af årsregnskab og skatteregnskab',
          value: 'årsregnskab',
        },
        {
          text: 'Jeg har bogført noget selv, men trænger hjælp',
          value: 'accounting_help',
        },
        {
          text: 'Jeg ønsker at i bogfører',
          value: 'all_inclusive',
        },
        {
          text: 'Hjælpepakker, hvor staten dækker faste udgifter eller kompenserer for tab af omsætning',
          value: 'hjælpepakker',
        },
        {
          if: ({cvr_search}) => cvr_search?.nyesteVirksomhedsformKortBeskrivelse === 'IVS',
          text: 'Omdannelse af IVS til APS',
          value: 'omdannelse',
        },
        {
          text: 'Nedlukning',
          value: 'nedlukning',
        },
      ]
    },
    {
      type: 'textarea',
      name: 'someText',
      title: 'Here our customers can ask us a really long question over multiple lines',
      required: true,
      placeholder: 'Type your answer here...',
    },
    {
      type: 'input',
      name: 'oneLineAnswer',
      title: 'One-line answer, no multiline option',
      required: true,
      placeholder: 'Type your answer here...',
    },
    {
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Type your email',
      required: true,
      placeholder: 'somebody@example.com',
    },
    {
      type: 'checkboxes',
      name: 'firstQuestion',
      title: 'Hvilken periode ønsker du hjælp til?',
      required: true,
      options: [
        {text: '2019'},
        {text: '2020'}
      ],
    },
    {
      type: 'rate',
      title: 'Hvor mange bank kontier har du tilknyttet virksomheden?',
      name: 'rate',
      rateMin: 0,
      rateMax: 10,
      rateStep: 1,
    },
    {
      type: 'custom',
      name: 'info',
      html: '<blockquote>If many revenue transactions, then ask whether the customer sometimes sells things abroad (meaning no VAT).</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },
    {
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Vi har modtaget dit spørgsmål nu</blockquote>',
      buttons: ['submit']
    },
  ]
}

export default json
