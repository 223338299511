import SurveyModel from "./Model"
import {post, get} from "utils/fetch"
import {routes} from "config/routes"
import debounce from 'utils/debounce'

const update = ({model, data, step}: {model: SurveyModel, data?: {[key: string]: any}, step?: string, form: string}) =>
  post(routes.api.response.update(model.customerId, model.formName), {
    session: model.sessionId,
    step: step || model.currentQuestionName,
    data
  })

export const updateResponse = debounce(update, 300)

export const submitResponse = ({model, update}: {model: SurveyModel, update: any}) =>
  post(routes.api.response.submit(model.customerId, model.formName), {
    session: model.sessionId,
    step: model.currentQuestionName,
    update
  })

export const createCheckoutSession = (customerIdHash: string, description: string, value:number, currency : string, successUrl : string, cancelUrl : string) =>
  post(routes.api.stripeCheckout.createCheckoutSession(), {
    customerIdHash,
    description,
    value,
    currency,
    successUrl,
    cancelUrl
  })

export const createCustomer = () =>
  get(routes.api.customer.createCustomer())

export const getViiaLoginLink = (customerIdHash: string) =>
  post(routes.api.customer.getViiaLoginLink(), {customerIdHash})

export const checkViiaAccess = (customerIdHash: string) =>
  post(routes.api.customer.checkViiaAccess(), {customerIdHash})
