import React from 'react'
import Model from 'Survey/Model'
import {observer} from 'mobx-react'
import {setValue, resetValue, toggleArrayValue, prevQuestion} from 'Survey/actions'
import style from './style.module.scss'
import Font from 'Survey/Shared/Font/Font'
import Text from 'Survey/Shared/Text/Text'
import {Option} from "Survey/types"
import cn from 'classnames'

type Props = {
  model: Model
  name: string
  multiple?: boolean
  option: Option
  className?: string
  checked?: boolean
}

export default observer(function CheckboxComponent(
  {model, name, multiple, className, checked, option: {text, link, value = text}}: Props
)  {
  if (checked === undefined) {
    const savedValue = model.values[name]
    checked = multiple ? savedValue && savedValue.includes(value) : savedValue === value
  }

  const handleChange = () => {
    if (multiple) {
      toggleArrayValue(model, name, value)
    } else if (checked)
      resetValue(model, name)
    else
      setValue(model, name, value, true)
  }

  const handelClick = () => {
    prevQuestion(model);
  }

  const content = <React.Fragment>
    <Font size='l' className={style.choiceText}><Text model={model} text={text}/></Font>
    <div className={style.check}>
      <svg className={style.checkIcon} height="13" width="16">
        <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"/>
      </svg>
    </div>
  </React.Fragment>

  return <label className={cn(style.choiceWrap, className)}>
    {!link &&
    <input
      name={name}
      checked={checked || false}
      onChange={handleChange}
      className={style.input}
      type='checkbox'
    />
    }
    {
      link ?
        <a href={link} className={style.choice} onClick={handelClick} target='_blank'>{content}</a> :
        <div className={style.choice}>{content}</div>
    }
  </label>
})
