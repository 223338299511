export default (fn: (...args: any[]) => any, time: number) => {
  let timeout: any
  return (...args: any[]) => {
    if (timeout)
      clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
      timeout = undefined
    }, time)
  }
}
