import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  allowNewCustomer: true,
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> for at lave et linjeskift',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'what',
      title: 'Hvilket regnskabsprogram er der brugt?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'E-conomic',
          value: 'e_conomic',
        }, {
          text: 'Dinero',
          value: 'dinero',
        }, {
          text: 'Billy',
          value: 'billy',
        }, {
          text: 'Excel',
          value: 'excel',
        }, {
          text: 'Andet',
          value: 'andet',
        },
      ]
    }, {
      type: 'payment',
      name: 'checkout',
      title: 'Tilbud',
      required: false,
      currency: 'dkk',
      description: 'Revisor1',
      checkoutItems: [
        {
          description: "Pris for regnskabstjek",
          expression: (values, subtotal) => {
            return 240
          },
        },{
          description: "Moms",
          expression: (values, subtotal) => {
            return subtotal * 0.25
          },
        },
      ]
    },{
      type: 'input',
      name: 'oneLineAnswer',
      title: 'Hvad er dit telefonnummer?',
      required: false,
      placeholder: 'Skriv dit telefonnummer her...',
    }, {
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Tusind tak!</blockquote>',
      buttons: ['submit']
    },

  ]
}

export default json
