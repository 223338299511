import React from 'react'
import Model from 'Survey/Model'
import cn from 'classnames'
import style from './style.module.scss'
import {observer} from 'mobx-react'
import {prevQuestion, nextQuestion} from 'Survey/actions'

type Props = {
  model: Model
}

export default observer(function PaginationComponent ({model, model: {items, currentQuestion}}: Props) {
  const prev = () =>
    prevQuestion(model)

  const next = () =>
    nextQuestion(model)

  return <div className={style.controls}>
    <div onClick={prev} className={cn(style.button, currentQuestion > 0 && style.active)}>
      <svg height="9" width="14">
        <path d="M11.996 8.121l1.414-1.414L6.705 0 0 6.707l1.414 1.414 5.291-5.293z"/>
      </svg>
    </div>
    <div className={style.sep}/>
    <div onClick={next} className={cn(style.button, model.nextDependenciesSatisfied && style.active)}>
      <svg height="9" width="14">
        <path d="M12.293.293l1.414 1.414L7 8.414.293 1.707 1.707.293 7 5.586z"/>
      </svg>
    </div>
  </div>
})
