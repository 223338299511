import React from 'react'
import Survey from 'Survey/Model'
import Fieldset from "Survey/Fieldset/Fieldset"
import {observer} from "mobx-react"
import {InputData} from 'Survey/types'
import InputMask from "react-input-mask";
import {setValue} from 'Survey/actions'
import style from './style.module.scss'
/*import ReactPixel from 'react-facebook-pixel'

ReactPixel.init('1324729324292440')*/

type Props = {
  number: number
  model: Survey
  data: InputData
}

export default observer(function InputComponent ({number, model, data: {title, name, required, placeholder, mask, text}}: Props)  {
  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) =>
    setValue(model, name, target.value.replace("_", ""))

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.indexOf('arrow') !== -1)
      e.stopPropagation()
  }

  return <Fieldset number={number} title={title} model={model} name={name} required={required}>
    { text && <blockquote dangerouslySetInnerHTML={{__html: text}}></blockquote>}
    <InputMask 
      className={style.input}
      placeholder={placeholder}
      maskChar={null}
      onKeyDown={handleKeyDown}
      value={model.values[name] || ''}
      onChange={handleChange}
      mask={mask ? mask : ''}
    />
  </Fieldset>
})
