import SurveyModel from 'Survey/Model'
import {updateResponse, submitResponse} from "./api"

export const setResponseReady = (model: SurveyModel) => {
  model.state = 'running'
  window.history.replaceState({}, '', `?customer_id=${model.customerId}&step=${model.currentQuestionName}&form=${model.formName}`)
}

export const setCurrentQuestion = (model: SurveyModel, number: number, alreadySaved?: boolean) => {
  model.setCurrentQuestion(number)
  if (!alreadySaved)
    updateResponse({model})
  window.history.pushState({}, '', `/?customer_id=${model.customerId}&step=${model.currentQuestionName}&form=${model.formName}`)
}

export const nextQuestion = (model: SurveyModel, alreadySaved?: boolean) => {
  if (!model.nextDependenciesSatisfied) return

  const number = model.nextQuestionNumber()
  number && setCurrentQuestion(model, number, alreadySaved)
}

export const prevQuestion = (model: SurveyModel) => {
  const number = model.prevQuestionNumber()
  number !== undefined && setCurrentQuestion(model, number)
}

const animationMs = 250

export const setValue = (model: SurveyModel, name: string, value: any, openNext?: boolean) => {
  model.setValue(name, value)
  if (!openNext)
    return updateResponse({model, data: {[name]: value}})

  const nextQuestionNumber = model.nextQuestionNumber() || model.currentQuestion
  const data: any = {[name]: value}
  model.unavailableAnswers.forEach(name => {
    data[name] = null
    model.setValue(name, undefined)
  })
  updateResponse({model, data, step: model.items[nextQuestionNumber].name})
  const {currentQuestion} = model
  setTimeout(() => {
    if (model.currentQuestion === currentQuestion)
      nextQuestion(model, true)
  }, animationMs)
}

export const resetValue = (model: SurveyModel, name: string) => {
  updateResponse({model, data: {[name]: null}})
  model.removeValue(name)
}

export const toggleArrayValue = (model: SurveyModel, name: string, value: any) => {
  const array = model.toggleArrayValue(name, value)
  updateResponse({model, data: {[name]: array}})
}

export const submit = async (model: SurveyModel) => {
  const firstErrorQuestionNumber = model.validate()
  if (firstErrorQuestionNumber !== undefined)
    return setCurrentQuestion(model, firstErrorQuestionNumber)

  model.state = 'submitting'

  const update: any = {}
  model.items.forEach((item: any) => {
    const {name} = item
    if (name && item.default !== undefined && !model.touched[name])
      update[name] = item.default
  })

  await submitResponse({model, update})
  model.state = 'submitted'
}

export const setDefaults = (model: SurveyModel) => {
  model.items.forEach((item: any) => {
    if (item.name && item.default !== undefined)
      model.values[item.name] = item.default
  })
}

export const translate = (model: SurveyModel, key: string) =>
  model.data.locales[model.locale][key]
