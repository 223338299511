import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  allowNewCustomer: true,
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> for at lave et linjeskift',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'custom',
      name: 'introduction',
      html: '<blockquote>Vi vil gerne tilbyde 25% rabat til dem, der betaler 50 kroner, inden de taler med os. Du kan altid få disse penge tilbage. ' +
          'Du kan se vores priser på <a href="https://revisor1.dk" target="_blank">Revisor1.dk</a>. <br><br>I nogle få tilfælde kan vi ikke ' +
          'håndtere dit regnskab, og dette vil blive afdækket i en samtale inden for nogle dage efter din betaling. <br><br>' +
          'Tilbuddet gælder kun første år for nye kunder.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    }, {
      type: 'input',
      name: 'oneLineAnswer',
      title: 'Hvad er dit telefonnummer?',
      required: false,
      placeholder: 'Skriv dit telefonnummer her...',
    }, {
      type: 'payment',
      name: 'checkout',
      title: 'Tilbud',
      required: false,
      currency: 'dkk',
      description: 'Revisor1',
      checkoutItems: [
        {
          description: "Pris eksklusive moms",
          expression: (values, subtotal) => {
            return 40
          },
        },{
          description: "Moms",
          expression: (values, subtotal) => {
            return subtotal * 0.25
          },
        },
      ]
    },{
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Tusind tak!</blockquote>',
      buttons: ['submit']
    },

  ]
}

export default json
