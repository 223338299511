import React from 'react'
import Survey from 'Survey/Model'
import Fieldset from "Survey/Fieldset/Fieldset"
import {observer} from "mobx-react"
import {PaymentData} from 'Survey/types'
import {setValue} from 'Survey/actions'
import style from './style.module.scss'
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from 'Survey/api'
import ReactPixel from 'react-facebook-pixel';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

ReactPixel.init('1324729324292440')

type Props = {
  number: number
  model: Survey
  data: PaymentData
}

export default observer(function PaymentComponent ({number, model, data: {title, name, required, description, currency, _amount, checkoutItems}}: Props)  {
  let errorTxt : string = "";
  let amount = _amount || 0

  const handleClick = async () => {
    // Get Stripe.js instance
    const stripe : any = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await createCheckoutSession(model.customerId, description , amount*100, currency, window.location + '&success=1', window.location + '&cancel=1')

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    });

    if (result.error) {
      errorTxt = result.error.message
    }
  };  

  ReactPixel.pageView()

  const match = window.location.search.match(/\bsuccess=([^&]+)/)
  if (match && !model.values[name]){
    setValue(model, name, amount)
    ReactPixel.track('Purchase', {currency: "DKK", value: amount});
  }
  
  const listItems = checkoutItems.map((checkoutItem, idx) => {
      if(checkoutItem._value){
        return <tr key={"tr_calc_" + idx} className={style.amount} > 
          <td>{checkoutItem.description}:</td>
          <td>{(checkoutItem._value || 0).toLocaleString('de-DE', { style: 'currency', currency: 'DKK' }) }</td>
        </tr>
      } 
    })
    
  return <Fieldset number={number} title={title} model={model} name={name} required={required}>
    <React.Fragment>
      <table>
        <tbody>
          {listItems}
        </tbody>
        <tfoot>
          <tr className={style.amount}>
            <td>Totalt: </td>
            <td>{(amount || 0).toLocaleString('de-DE', { style: 'currency', currency: 'DKK' }) }</td>
          </tr>
        </tfoot>
      </table>
    </React.Fragment>
    <br/>
    <button disabled={amount < 10} className={style.button} role="link" onClick={handleClick}>
        Betal {(amount || 0).toLocaleString('de-DE', { style: 'currency', currency: 'DKK' })}
    </button>
  <div>{errorTxt}</div>
  </Fieldset>
})
