import React from 'react'
import {RateData} from "Survey/types"
import Fieldset from 'Survey/Fieldset/Fieldset'
import style from './style.module.scss'
import Model from 'Survey/Model'
import Option from "./Option"

type Props = {
  number: number
  model: Model
  data: RateData
}

export default function RateComponent({number, model, data: {title, name, required, rateMin, rateMax, rateStep = 1}}: Props)  {
  const options: React.ReactNode[] = []

  for (let i = rateMin; i <= rateMax; i += rateStep)
    options.push(<Option key={i} number={i} model={model} name={name} />)

  return <Fieldset number={number} title={title} model={model} name={name} required={required}>
    <div className={style.options}>
      {options}
    </div>
  </Fieldset>
}
