import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {SurveyComponent} from 'Survey/Survey'
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SurveyComponent/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
