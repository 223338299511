import React from 'react'
import Font from "Survey/Shared/Font/Font"
import {nextQuestion, submit, translate} from 'Survey/actions'
import {ButtonAction} from 'Survey/types'
import Survey from 'Survey/Model'
import style from './style.module.scss'

type ButtonSettings = {
  text(model: Survey): string
  hint?(model: Survey): string
  onClick(model: Survey): void
}

const buttonSettings: {[key: string]: ButtonSettings} = {
  next: {
    text(model: Survey) {
      return translate(model, 'nextButton')
    },
    hint(model: Survey) {
      return translate(model, 'nextButtonHint')
    },
    onClick(model: Survey) {
      nextQuestion(model)
    },
  },
  submit: {
    text(model: Survey) {
      return translate(model, 'submitButton')
    },
    onClick(model: Survey) {
      submit(model)
    },
  },
}

type Props = {
  model: Survey,
  buttonsAlign?: string,
  buttons: (keyof typeof ButtonAction)[],
  hideHint?:boolean
}

export default function ButtonsComponent ({model, buttonsAlign = 'center', buttons, hideHint}: Props)  {
  return <div
    className={style.buttons}
    style={buttonsAlign ? {justifyContent: buttonsAlign} : undefined}
  >
    {buttons.map((type, i) => {
      const button = buttonSettings[type]
      const hint = !hideHint && button.hint && button.hint(model)

      return <React.Fragment key={i}>
        <button
          key={i}
          className={style.button}
          onClick={() => button.onClick(model)}
        >
          <Font size='l'>{button.text(model)}</Font>
        </button>
        {hint &&
          <div className={style.buttonDesktopHint} dangerouslySetInnerHTML={{__html: hint}}/>
        }
      </React.Fragment>
    })}
  </div>
}
