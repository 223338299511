import { SurveyJSON } from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Al information er afsendt',
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'custom',
      name: 'header',
      html: '<h3>Årsafslutningsskema - Selskab</h3>' +
        '<blockquote>For at kunne afslutte din selvangivelse har ' + 
        'vi behov for at du svarer på følgende spørgsmål. <br/>' + 
        '(Skriv ”0” som værdi, hvis du ikke har færdigvarelager ' + 
        'eller hvis du ikke ønsker udbytte.) <br/>' + 
        'Er du i tvivl om noget, så skriv til ' +
        '<a href="mailto:kundeservice@revisor1.dk">kundeservice@revisor1.dk</a>' +
        '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },/*{

      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Skriv firmanavn eller mailadresse',
      required: true,
      placeholder: 'example@email.com',
    }*/
    /*{
      type: 'checkboxes',
      name: 'intet_at_angive',
      title: 'Intet at angive?',
      required: false,
      multiple: false,
      text: 'Hvis du intet har at angive, så marker feltet nedenfor.<br/> ' +
        'Klik efterfølgende på knappen "send" nederst på siden.',
      options: [
        {
          text: 'Intet at angive',
          value: 'intet_at_angive',
        }
      ]
    }*/{
      type: 'input',
      name: 'færdigvarelager',
      title: 'Færdigvarelager',
      text: 'Har du færdigvarelager, så angiv venligst værdien af dine ' + 
        'færdigvarer i danske kroner: <br/> Det skal være varelagerets værdi '+
        'på regnskabsårets sidste dag',
      required: false,
      placeholder: '0',
    },{
      type: 'input',
      name: 'færdigvarelager_svind',
      title: 'Færdigvarelager',
      text: 'Angiv eventuelt svind i dit færdigvarelager i danske kroner:',
      required: false,
      placeholder: '0',
    },{
      type: 'input',
      name: 'udbytte',
      title: 'Udbytte',
      text: 'Såfremt du har mulighed for at tage udbytte i selskabet, ' + 
        'ønsker du da at gøre brug af denne mulighed, og i så fald for ' + 
        'hvor stort et beløb i danske kroner:',
      required: false,
      placeholder: '0',

    /*},{
      type: 'input',
      name: 'specifikation_udbytte',
      title: 'Specifikation af udbytte',
      text: 'Udfyldes kun, hvis du tager udbytte i selskabet. <br/><br/>' + 
        'Angiv hvem (CPR) eller hvilket firma (CVR), der ønsker ' + 
        'at tage udbytte i selskabet. <br/>' +
        'Angiv udbytte størrelsen nedenfor de enkelte CVR/CPR numre. ',
      required: false,*/
    },
    //////YELD SESSION: READ YELD FROM 3 COMPANIES//////
    {
      title: 'Udbytte',
      text: 'Udfyldes kun, hvis du tager udbytte i selskabet.<br/>' +
          'Angiv hvem (CPR) eller hvilket firma (CVR), der ønsker ' +
          'at tage udbytte i selskabet. <br/>' +
          'Angiv udbytte størrelsen nedenfor de enkelte CVR/CPR numre. <br/><br/>' +
          'Angiv første CVR/CPR nummer:',
      type: 'input',
      name: 'cvr1',
      placeholder: 'CVR/CPR nummer',
      required: false,
    },{
      if:(values) => values.cvr1,
      title: 'Angiv udbytte størrelse:',
      type: 'input',
      name: 'cvr1_udbytte',
      placeholder: '0',
      required: false,
    },{
      if:(values) => values.cvr1,
      title: 'Angiv evt. næste CVR/CPR nummer:',
      type: 'input',
      name: 'cvr2',
      placeholder: 'CVR/CPR nummer',
      required: false,
    },{
      if:(values) => values.cvr2,
      title: 'Angiv udbytte størrelse:',
      type: 'input',
      name: 'cvr2_udbytte',
      placeholder: '0',
      required: false,
    },{
      if:(values) => values.cvr2,
      title: 'Angiv evt. tredje CVR/CPR nummer:',
      type: 'input',
      name: 'cvr3',
      placeholder: 'CVR/CPR nummer',
      required: false,
    },{
      if:(values) => values.cvr3,
      title: 'Angiv udbytte størrelse:',
      type: 'input',
      name: 'cvr3_udbytte',
      placeholder: '0',
      required: false,
    },
    //////END YELD SESSION//////
    {
      // if:(values) => values.intet_at_angive != 'intet_at_angive',
      type: 'checkboxes',
      name: 'afskrivninger',
      title: 'Afskrivninger',
      text: 'Udfyldes kun hvis du har aktiver, der afskrives på. <br/> ' +
        'Har du solgt aktiver?',
      required: false,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    },{
      type: 'checkboxes',
      name: 'offentliggørelse_omsætning',
      title: 'Offentliggørelse af din omsætning',
      text: 'Ønsker du at oplyse din Omsætning i dit offentlige årsregnskab?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'ja',
        },
        {
          text: 'Nej',
          value: 'nej',
        }
      ]
    },
    {
      type: 'custom',
      name: 'header',
      html: '<h3>Er du klar til at indsende?</h3>' +
        '</blockquote>',
      buttonsAlign: 'left',
      buttons: ['submit']
    },
  ]
}

export default json
