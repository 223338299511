import State from './state'

const companySearchURL = process.env.REACT_APP_COMPANY_SEARCH_URL
let lastSearch: string | undefined = undefined

export const search = async (state: State, search: string) => {
  if (search === '')
    return state.emptySearch()

  state.setSearch(search)

  lastSearch = search

  const q = encodeURIComponent(search)
  let res = <any>await window.fetch(`${companySearchURL}/api/search/company?text=${q}&page=1&number=10`)



  if (lastSearch !== search) return
  res = await res.json()
  if (lastSearch !== search) return

  state.setCompanies(res)
}
