import React from 'react'
import style from './style.module.scss'
import cn from 'classnames'
import Model from 'Survey/Model'
import {observer} from 'mobx-react'

type Props = {
  children: React.ReactNode
  number: number
  model: Model
}

export default observer(function ScreenComponent ({children, number, model: {currentQuestion, prevQuestion}}: Props)  {
  if (number !== currentQuestion && number !== prevQuestion)
    return null

  return <div
    className={
      cn(
        style.screenWrapper,
        number < currentQuestion ? style.up : number > currentQuestion ? style.down : style.current
      )
    }
  >
    <div className={cn(style.screen, style.active)}>
      <div className={style.blockScroller}>
        <div className={style.blockScrollerInner}>
          {children}
        </div>
      </div>
    </div>
  </div>
})
