import React from 'react'
import Font from 'Survey/Shared/Font/Font'
import Survey from "Survey/Model";
import {observer} from "mobx-react"
import Buttons from "Survey/Elements/Buttons/Buttons"
import style from './style.module.scss'
import {CheckboxesData} from 'Survey/types'

type ActionsProps = {
  model: Survey
  name: string
}

const Actions = observer(({model, name}: ActionsProps) => {
  const error = name && model.errors[name]
  if (model.submitted && error)
    return <div className={style.error}>{error}</div>

  const item = model.findItem(name)
  const value = model.values[name]
  let nextButton = false
  if (item) {
    if (item.type === 'checkboxes' && ((item as CheckboxesData).multiple === true || !item.required))
      nextButton = (Array.isArray(value) && value.length > 0) || !item.required
    if ((item.type === 'textarea' || item.type === 'input') && (value || !item.required))
      nextButton = true
  }

  return nextButton ? <Buttons buttonsAlign={'left'} model={model} buttons={['next']} hideHint={(item.type === 'textarea')} /> : null
})

type Props = {
  children: React.ReactNode
  number: number
  title: string
  model: Survey
  name: string
  required?: boolean
}

export default function FieldSetComponent ({children, number, title, model, name, required}: Props)  {
  return <React.Fragment>
    <div className={style.questionHeader}>
      <div className={style.counter}>
        <div className={style.counterValue}>
          <Font size='m'>{number}</Font>
        </div>
        <svg className={style.counterArrow} height="10" width="11">
          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"/>
          <path d="M8 4v2H0V4z"/>
        </svg>
      </div>
      <div className={style.question}>
        <Font size='xl'>{title}</Font>
        {required && <Font size='xl' className={style.requiredAsterisk}>*</Font>}
      </div>
    </div>
    <div tabIndex={-1} className={style.answer}>
      {children}
    </div>
    <div className={style.actions}>
      {name && <Actions model={model} name={name} />}
    </div>
  </React.Fragment>
}
