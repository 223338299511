import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Finished',
  allowNewCustomer: true,

  errors: {
    required: 'Please fill this out'
  },
  locales: {
    dk: {
      nextButton: 'Continue',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Submit',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'input',
      name: 'hours',
      title: 'How many hours are you able to work per week?',
      required: true,
      placeholder: '10',
      mask: '999',
    },{
      type: 'checkboxes',
      name: 'scammer_emails_reason',
      title: 'Why do scammers from Nigeria often send out absurd or obviously fake emails?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Their english is bad because they are from Nigeria',
          value: 'bad_english',
        }, {
          text: 'To get people to talk about the emails, creating a buzz.',
          value: 'creating_buzz',
        }, {
          text: 'To only get responses from people who are easy to trick.',
          value: 'filter_susceptible',
        }, {
          text: 'Because unusual content captures more attention.',
          value: 'capture_attention',
        }, {
          text: 'They underestimate the intelligence of their targets.',
          value: 'underestimate_targets',
        },
      ]
    },{
      type: 'checkboxes',
      name: 'sales_funnel',
      title: 'If you compare a recruitment process to a sales funnel, where should you spend the most time on each candidate?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'At the top',
          value: 'top',
        }, {
          text: 'At the bottom',
          value: 'bottom',
        }, {
          text: 'Towards the sides',
          value: 'sides',
        }, {
          text: 'It\'s conceptually incorrect to compare recruitment to a sales funnel.',
          value: 'incorrect_comparison',
        }, {
          text: 'On the inside',
          value: 'inside',
        },
      ]
    },{
      type: 'checkboxes',
      name: 'believe_biased',
      title: 'How much do you agree with this statement about you: "I believe I am biased."',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Strongly Agree',
          value: 'strongly_agree',
        }, {
          text: 'Agree',
          value: 'agree',
        }, {
          text: "Neutral / I don't know",
          value: 'neutral',
        }, {
          text: 'Disagree',
          value: 'disagree',
        }, {
          text: 'Strongly Disagree',
          value: 'strongly_disagree',
        },
      ]
    },{
      if: (values) => (
          parseInt(values.hours) >= 20 &&
          values.scammer_emails_reason === 'filter_susceptible' &&
          values.sales_funnel === 'bottom' &&
          (values.believe_biased === 'strongly_agree' || values.believe_biased === 'agree')
      ),
      type: 'textarea',
      name: 'recruitment_strategy',
      title: 'You have answered correctly so far, which is why you are seeing this question. If I like your answer to the following question, then I will pay you $21 and I will give you a further test. ' +
          'After that I expect to have a lot of work for you. Question: ' +
          'You are the only person recruiting at a small online retail company called ShopEase. The company has decided to launch a new customer support chat feature. ' +
          'To staff this new service, you need to hire 100 customer support agents within one week. You can attract enough applicants through advertising and posting on job boards, ' +
          'so the question is what the recruitment process should be, given that you cannot ask for help in recruiting. In other words, how will you arrive at the 100 best candidates? ' +
          'Write a maximum of 600 words.',
      required: true,
      placeholder: 'Please describe your proposed recruitment process here.',
    },{
      if: (values) => (
          parseInt(values.hours) >= 20 &&
          values.scammer_emails_reason === 'filter_susceptible' &&
          values.sales_funnel === 'bottom' &&
          (values.believe_biased === 'strongly_agree' || values.believe_biased === 'agree')
      ),
      type: 'input',
      name: 'upwork_name',
      title: 'What is your name on upwork?',
      required: true,
    },{
      if: (values) => (
          parseInt(values.hours) >= 20 &&
          values.scammer_emails_reason === 'filter_susceptible' &&
          values.sales_funnel === 'bottom' &&
          (values.believe_biased === 'strongly_agree' || values.believe_biased === 'agree')
      ),
      type: 'custom',
      name: 'bid_instructions',
      html: '<blockquote>If you bid exactly the amount of $21 on the Upwork project, then I will accept your bid if I liked the answer you came up with regarding recruiting. If I did not like your answer, then you will not hear from me. ' +
          'I will pay you within one week.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      if: (values) => !(
          parseInt(values.hours) >= 20 &&
          values.scammer_emails_reason === 'filter_susceptible' &&
          values.sales_funnel === 'bottom' &&
          (values.believe_biased === 'strongly_agree' || values.believe_biased === 'agree')
      ),
      type: 'custom',
      name: 'info_only_bids_with_9',
      html: '<blockquote>I will only consider bids on Upwork where the bid amount ends with 9, since then I know that you have gone through this wizard.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Press submit</blockquote>',
      buttons: ['submit']
    }
  ]
}

export default json
