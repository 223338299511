import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      "type": "checkboxes",
      "name": "competences",
      "title": "What does it mean to reproduce a bug? Select all that apply.",
      "required": true,
      "multiple": true,
      "options": [
        {
          "text": "To create a similar problem in a different part of the codebase.",
          "value": "answer1"
        }, {
          "text": "To document the bug and write a detailed report on its behavior and impact.",
          "value": "answer2"
        }, {
          "text": "To apply a patch or update that eliminates the bug from the system.",
          "value": "answer3"
        }, {
          "text": "To escalate the issue to a higher-level support team for further analysis.",
          "value": "answer4"
        }, {
          "text": "To introduce a new bug with similar characteristics in another application.",
          "value": "answer5"
        }, {
          "text": "To rewrite the affected code section to prevent the bug from occurring.",
          "value": "answer6"
        }, {
          "text": "To conduct a series of tests to confirm the bug no longer exists after changes.",
          "value": "answer7"
        }, {
          "text": "All of the above",
          "value": "all"
        }, {
          "text": "None of the above",
          "value": "none"
        }
      ]
    },{
      type: 'checkboxes',
      name: 'test',
      title: 'Would you be willing to take a programming test that takes approximately 1 hour to complete. The test is hard and you will get paid $20 ONLY if you pass the test. If you complete the test, then I can give you a lot of projects',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Yes',
          value: 'want_test',
        }, {
          text: 'No',
          value: 'dont_want_test',
        }
      ]
      /*    }, {
            type: 'input',
            name: 'upwork_name',
            title: 'What is your name on upwork',
            required: true,
            placeholder: 'David A',*/
    }, {
      type: 'input',
      name: 'hours',
      title: 'How many hours are you able to work per week?',
      required: true,
      placeholder: '10',
      mask: '999',
    },{
      type: 'input',
      name: 'hours',
      title: 'How many dollars would you charge per hour for ongoing work?',
      required: true,
      placeholder: '5',
      mask: '999',
    },{
      type: 'input',
      name: 'hours',
      title: 'How many dollars would you charge per hour for ongoing work?',
      required: true,
      placeholder: '5',
      mask: '999',
    },{
      if: (values) => (values.competences && values.competences.indexOf('postgres') >= 0 && values.hours && !isNaN(values.hours) && values.hours > 25),
      type: 'checkboxes',
      name: 'test',
      title: 'Would you be willing to take a programming test that takes approximately 1 hour to complete. The test is hard and you will get paid $20 ONLY if you pass the test. If you complete the test, then I can give you a lot of projects',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Yes',
          value: 'want_test',
        }, {
          text: 'No',
          value: 'dont_want_test',
        }
      ]
/*    }, {
      type: 'input',
      name: 'upwork_name',
      title: 'What is your name on upwork',
      required: true,
      placeholder: 'David A',*/
    }, {
      if: (values) => (values.test === 'want_test' && values.competences && values.competences.indexOf('postgres') >= 0),
      type: 'custom',
      name: 'info',
      html: '<blockquote>If you bid exactly the amount of $5 on the upwork project, then I will accept your bid, and pay you immediately (well, I will check every second day or so). I will also send you the PostgreSQL test. If you pass the test on your first attempt, then I will send you a bonus of $20 as promised.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      if: (values) => (!values.test || values.test === 'dont_want_test' || values.competences && values.competences.indexOf('postgres') == -1),
      type: 'custom',
      name: 'info_only_bids_with_9',
      html: '<blockquote>I will only consider bids on Upwork where the bid amount ends with 9, since then I know that you have gone through this wizard.</blockquote>',
      buttonsAlign: 'left',
      buttons: ['next']
    },{
      type: 'custom',
      name: 'submit',
      html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
      buttons: ['submit']
    }
  ]
}

export default json
